:root {
    --font: Manrope, Arial, -apple-system, BlinkMacSystemFont, "Segoe UI", "Open Sans", "Helvetica Neue", sans-serif;
    --tcolor: #172B3D;
    --bgcolor: #EFF7F1;
    --lcolor: #4CCB70;
    --lhcolor: #289B7F;
    --sel-lang-active: #fff;
    --header-bg: #fff;
    --menu-hover-games: #F8B035;
    --menu-hover-apps: #7126C1;
    --menu-hover-top: #F74A2F;
    --menu-hover-news: #368BE1;
    --hmenu-more-grad: linear-gradient(90deg, rgba(23, 43, 61, 0) 0%, rgba(23, 43, 61, 0.05) 100%);
    --footer-bg: #142636;
    --main-heading: #289B7F;
    --dark-section-bg: #172B3D;
    --dark-section-grad: linear-gradient(0deg, #172B3D 0%, #0E1C29 100%);
    --dark-circle-blur: radial-gradient(closest-side, rgba(23, 43, 61, 0) 0, rgba(23, 43, 61, 0.6) 50%, rgba(23, 43, 61, 1) 94%);
    --form-control-bg: #fff;
    --form-control-brd: #E7E9EB;
    --form-control-brd-f: #D0D4D8;
    --placeholder: #8B959E;
    --cloud-c1: #4ccb70;
    --cloud-c2: #fbfcf3;
    --entry-bg: #fff;
    --entry-info: #8B959E;
    --entry-info-sep: #E6EFF4;
    --entry-label: rgba(255, 255, 255, .5);
    --entry-pattern: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%238B959E' d='M2,8a2,2,0,1,0,2,2A2,2,0,0,0,2,8Zm8-8a2,2,0,1,0,2,2A2,2,0,0,0,10,0Z'/%3E%3C/svg%3E");
    --entry-pattern-d: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill='%234CCB70' d='M2,8a2,2,0,1,0,2,2A2,2,0,0,0,2,8Zm8-8a2,2,0,1,0,2,2A2,2,0,0,0,10,0Z'/%3E%3C/svg%3E");
    --block-bg: #fff;
    --block-bg-transp: rgba(255, 255, 255, 0);
    --block-dark-bg: #273D52;
    --modal-bg: #fff;
    --cat-menu: #28977B;
    --cat-menu-h: #172B3D;
    --spoiler: #f7f7f7;
    --spoiler-h: #EDFAF0;
    --searchsug: #f7f7f7;
    --searchsug-item: #fff;
    --nocomms: #765846;
    --coms-meta: rgba(23, 43, 61, .5);
    --coms-meta-h: rgba(23, 43, 61, .8);
    --line: #EBECED;
    --scrollbar: #F7F7F7;
    --scrollbar-thumb: #D0D0D0;
    --scrollbar-track: #F7F7F7;
    --loading-bg: rgba(255, 255, 255, .9);
    --spec-fade: rgba(23, 41, 61, 1);
    --spec-fade-transp: rgba(23, 41, 61, 0);
    --select-arrow: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23172B3D' d='M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z'/%3E%3C/svg%3E");
    --pageform-img: url(/public/images/page-illustation.png);
    --pageform-bg: #EFF7F1
}

html.darktheme {
    --tcolor: #fff;
    --bgcolor: #172B3D;
    --sel-lang-active: #172B3D;
    --header-bg: #2A4055;
    --menu-hover-apps: #9A53E7;
    --hmenu-more-grad: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
    --footer-bg: #091521;
    --main-heading: #4CCB70;
    --dark-section-bg: #0F1F2E;
    --dark-section-grad: linear-gradient(0deg, #0F1F2E 0%, #0B1B29 100%);
    --dark-circle-blur: radial-gradient(closest-side, rgba(15, 31, 46, 0) 0, rgba(15, 31, 46, 0.6) 50%, rgba(15, 31, 46, 1) 94%);
    --form-control-bg: #273D52;
    --form-control-brd: #3D5164;
    --form-control-brd-f: #53677B;
    --placeholder: #687786;
    --cloud-c1: #0F1F2E;
    --cloud-c2: #142636;
    --entry-bg: #273D52;
    --entry-info: #939EA9;
    --entry-info-sep: #3D5164;
    --entry-label: rgba(39, 61, 82, .5);
    --entry-pattern: var(--entry-pattern-d);
    --block-bg: #273D52;
    --block-bg-transp: rgba(39, 61, 82, 0);
    --block-dark-bg: #213548;
    --modal-bg: #2A4055;
    --cat-menu: rgba(76, 203, 112, .1);
    --cat-menu-h: rgba(76, 203, 112, .2);
    --spoiler: rgba(255, 255, 255, 0.05);
    --spoiler-h: rgba(255, 255, 255, 0.1);
    --searchsug: #2A4055;
    --searchsug-item: rgba(255, 255, 255, 0.05);
    --nocomms: #fede4a;
    --coms-meta: rgba(255, 255, 255, .5);
    --coms-meta-h: rgba(255, 255, 255, .8);
    --line: rgba(255, 255, 255, 0.05);
    --scrollbar: #2A4055;
    --scrollbar-thumb: #0F1F2E;
    --scrollbar-track: #2A4055;
    --loading-bg: rgba(0, 0, 0, .9);
    --spec-fade: rgba(15, 31, 46, 1);
    --spec-fade-transp: rgba(15, 31, 46, 0);
    --select-arrow: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23748390' d='M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z'/%3E%3C/svg%3E");
    --pageform-img: url(/public/images/page-illustation-night.png);
    --pageform-bg: #0d1d2b
}

@font-face {
    font-family: manrope;
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Manrope'), local('Manrope-Regular'), url(/public/fonts/manrope-v3-cyrillic-regular.woff2) format('woff2')
}

@font-face {
    font-family: manrope;
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Manrope Bold'), local('Manrope-Bold'), url(/public/fonts/manrope-v3-cyrillic-700.woff2) format('woff2')
}

[class^=i__],
[class*=" i__"] {
    width: 1.5rem;
    height: 1.5rem;
    display: inline-block;
    vertical-align: top
}

@media(min-width:992px) {

    html::-webkit-scrollbar,
    .page-form-right::-webkit-scrollbar {
        width: 16px;
        background-color: var(--scrollbar)
    }

    html::-webkit-scrollbar-thumb,
    .page-form-right::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-thumb);
        border-radius: 16px;
        border: 4px solid var(--scrollbar)
    }

    html::-webkit-scrollbar-track,
    .page-form-right::-webkit-scrollbar-track {
        background-color: var(--scrollbar-track);
        border-radius: 16px;
        border: 4px solid var(--scrollbar)
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
    -webkit-box-sizing: border-box
}

.load * {
    transition: none !important
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

html {
    font-family: sans-serif;
    line-height: 1;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

html {
    display: block
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
    border: 0 solid rgba(0, 0, 0, .1);
    border-top-width: 1px;
    margin: 1.75rem 0
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,
ul,
dl {
    list-style: none;
    padding: 0;
    margin: 0
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0
}

dt {
    font-weight: 700
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,
strong {
    font-weight: 700
}

small,
.small {
    font-size: .75em
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, liberation mono, courier new, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto
}

figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle;
    border-style: none
}

svg {
    overflow: hidden;
    vertical-align: middle
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #99999e;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

select {
    word-wrap: normal
}

button,
[type=button],
[type=reset],
[type=submit] {
    -webkit-appearance: button;
    outline: none !important
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=radio],
input[type=checkbox] {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 0
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

body {
    font: normal .875rem/1.5 var(--font);
    color: var(--tcolor);
    background-color: var(--bgcolor);
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    min-width: 320px;
    position: relative
}

@media(min-width:768px) {
    body {
        font-size: 1rem
    }
}

.wrp,
.wrp-min {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: auto;
    margin-right: auto
}

@media(min-width:640px) {
    .wrp {
        max-width: 92rem
    }

    .wrp-min {
        max-width: 63rem
    }

    .wrp,
    .wrp-min {
        padding-left: 2rem;
        padding-right: 2rem
    }
}

.wrp .wrp-min,
.wrp-min .wrp-min {
    max-width: 59rem;
    padding-left: 0;
    padding-right: 0
}

a,
.btn-link {
    color: var(--lcolor);
    text-decoration: none
}

@media(min-width:992px) {

    a,
    .btn-link {
        transition: color .2s ease
    }

    a:hover,
    .btn-link:hover {
        color: var(--lhcolor)
    }
}

.toolbar {
    position: sticky;
    right: 0;
    z-index: 100;
    pointer-events: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #fff;
    padding: 1rem;
    margin-top: -4.5rem
}

.toolbar img,
.toolbar svg {
    display: block
}

.toolbar button,
.toolbar a {
    pointer-events: auto;
    cursor: pointer
}

.sel-dark-toggle {
    color: inherit !important;
    background-color: rgba(5, 14, 21, .75);
    margin-left: .5rem;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 50%
}

html.darktheme .sel-dark-toggle {
    color: #172b3d !important;
    background-color: rgba(255, 255, 255, .75)
}

html.darktheme .sel-dark-toggle>.i__sun {
    display: block !important
}

html.darktheme .sel-dark-toggle>.i__moon {
    display: none !important
}

.sel-dark-toggle,
.sel-lang>button {
    width: 2.5rem;
    height: 2.5rem;
    padding: .5rem;
    border: 0
}

.sel-lang {
    display: flex
}

.sel-lang>button {
    background: 0 0
}

.sel-lang>button img {
    border-radius: 50%
}

.sel-lang>button.active {
    cursor: default
}

.sel-lang>button.active img {
    background-color: var(--sel-lang-active);
    box-shadow: 0 0 0 .125rem var(--sel-lang-active), 0 0 0 .25rem #fede4a
}

@media(max-width:1259px) {
    .toolbar {
        left: 0;
        bottom: 0;
        position: fixed;
        margin-top: 0
    }

    .sel-dark-toggle {
        margin-left: auto
    }

    .sel-lang {
        height: 2.5rem;
        background-color: var(--sel-lang-active);
        border-radius: 1.25rem;
        box-shadow: 0 .25rem 1rem -.25rem rgba(0, 0, 0, .25)
    }
}

@media(min-width:1260px) {
    .toolbar {
        top: 0
    }

    .sel-lang>button {
        margin-left: .25rem
    }
}

@media(min-width:992px) {

    .sel-dark-toggle,
    .sel-lang>button {
        transition: transform .2s ease
    }

    .sel-lang>button img {
        transition: box-shadow .2s ease
    }

    .sel-dark-toggle:hover,
    .sel-lang>button:not(.active):hover {
        transform: scale(1.1)
    }
}

.cover {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover
}

.fit-cover {
    position: relative;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    flex-grow: 1
}

.fit-cover img {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    min-width: calc(100% + 1px);
    height: 100%;
    transform: translateX(-50%);
    object-fit: cover;
    object-position: center center;
    -o-object-position: center center;
    -o-object-fit: cover
}

.avatar.fit-cover {
    width: 3rem;
    height: 3rem;
    border-radius: 50%
}

.w-100 {
    width: 100%
}

.circle {
    border-radius: 50%
}

.dashed {
    position: relative;
    display: inline-block
}

.dashed::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 0;
    border-top: 1px dashed #9d9fa4
}

.truncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.uppercase {
    text-transform: uppercase
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.fw-b {
    font-weight: 700
}

.fw-n {
    font-weight: 400
}

.lh-norm {
    line-height: normal
}

.f-right {
    float: right
}

.f-left {
    float: left
}

.ww-break-word {
    word-wrap: break-word
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.c-inherit {
    color: inherit !important
}

.c-white {
    color: #fff !important
}

.c-green {
    color: var(--lcolor) !important
}

.c-red {
    color: #f9563d !important
}

.c-yellow {
    color: #fede4a !important
}

.c-dark {
    color: var(--tcolor) !important
}

.c-muted {
    color: #8a949d !important
}

.bg-inherit {
    background-color: inherit !important
}

.bg-white {
    background-color: #fff !important
}

.bg-green {
    background-color: var(--lcolor) !important
}

.bg-grey {
    background-color: #f7f7f7 !important
}

.bg-dark {
    background-color: #172b3d !important
}

.s-yellow {
    background-color: #f9bd3b;
    background-image: linear-gradient(180deg, #FEDE4A 0%, #F8B035 100%);
    box-shadow: 0 .25rem .5rem 0 rgba(251, 205, 66, .3)
}

.s-green {
    background-color: #45c368;
    background-image: linear-gradient(180deg, var(--lcolor) 0%, #3DBA60 100%);
    box-shadow: 0 .25rem .5rem 0 rgba(69, 195, 104, .3)
}

.s-purple {
    background-color: #9248e1;
    background-image: linear-gradient(180deg, #9B54E8 0%, #7126C1 100%);
    box-shadow: 0 .25rem .5rem 0 rgba(146, 72, 225, .3)
}

.s-red {
    background-color: #fb614a;
    background-image: linear-gradient(180deg, #FF715C 0%, #F74A2F 100%);
    box-shadow: 0 .25rem .5rem 0 rgba(255, 89, 64, .3)
}

.s-blue {
    background-color: #37a9e4;
    background-image: linear-gradient(180deg, #38B9E6 0%, #368BE1 100%);
    box-shadow: 0 .25rem .5rem 0 rgba(55, 169, 228, .3)
}

.s-telegram {
    background-color: #2ea6d9;
    background-image: linear-gradient(180deg, #37AEE2 0%, #1E96C8 100%);
    box-shadow: 0 .25rem .5rem 0 rgba(53, 172, 224, .3)
}

@media(min-width:992px) {

    .btn.s-yellow:hover,
    .download-line.s-yellow:hover {
        box-shadow: 0 .25rem .5rem 0 rgba(251, 205, 66, .3), 0 0 0 .25rem rgba(251, 205, 66, .3)
    }

    .btn.s-green:hover,
    .download-line.s-green:hover,
    .page_prev>a:hover,
    .page_next>a:hover {
        box-shadow: 0 .25rem .5rem 0 rgba(69, 195, 104, .3), 0 0 0 .25rem rgba(69, 195, 104, .3)
    }

    .btn.s-purple:hover,
    .download-line.s-purple:hover {
        box-shadow: 0 .25rem .5rem 0 rgba(146, 72, 225, .3), 0 0 0 .25rem rgba(146, 72, 225, .3)
    }

    .btn.s-red:hover,
    .download-line.s-red:hover {
        box-shadow: 0 .25rem .5rem 0 rgba(255, 89, 64, .3), 0 0 0 .25rem rgba(255, 89, 64, .3)
    }

    .btn.s-blue:hover,
    .download-line.s-blue:hover {
        box-shadow: 0 .25rem .5rem 0 rgba(55, 169, 228, .3), 0 0 0 .25rem rgba(55, 169, 228, .3)
    }

    .btn.s-telegram:hover {
        box-shadow: 0 .25rem .5rem 0 rgba(53, 172, 224, .3), 0 0 0 .25rem rgba(53, 172, 224, .3)
    }
}

.c-icon {
    min-width: 2rem;
    max-width: 2rem;
    height: 2rem;
    border-radius: 50%;
    padding: .375rem;
    display: inline-block;
    vertical-align: top
}

.c-icon>svg {
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    color: #fff
}

@media(min-width:640px) {
    .c-icon {
        min-width: 2.5rem;
        max-width: 2.5rem;
        height: 2.5rem;
        padding: .5rem
    }

    .c-icon>svg {
        width: 1.5rem;
        height: 1.5rem
    }
}

.muted {
    opacity: .5
}

.comment__content iframe,
.comment__content video,
.showfull__content iframe,
.showfull__content video {
    width: 720px;
    height: 420px;
    max-width: 100%
}

.small,
small {
    font-size: .75rem
}

@media(min-width:576px) {

    h1,
    .h1 {
        font-size: 2.5rem
    }

    h2,
    .h2 {
        font-size: 2rem
    }

    h3,
    .h3 {
        font-size: 1.5rem;
        font-weight: 700
    }
}

@media(max-width:575px) {

    h1,
    .h1 {
        font-size: 2rem
    }

    h2,
    .h2 {
        font-size: 1.8rem
    }

    h3,
    .h3 {
        font-size: 1.5rem;
        font-weight: 700
    }
}

h4,
.h4 {
    font-size: 1.45rem
}

h5,
.h5 {
    font-size: 1.25rem
}

h6,
.h6 {
    font-size: 1rem
}

.text {
    line-height: 1.65
}

.text h1,
.text h2 {
    font-size: 1.125rem
}

.text h3,
.text h4,
.text h5 {
    font-size: 1rem
}

.text h6 {
    font-size: .75rem;
    text-transform: uppercase
}

@media(min-width:768px) {

    .text h1,
    .text h2 {
        font-size: 1.25rem
    }

    .text h3,
    .text h4,
    .text h5 {
        font-size: 1rem
    }
}

.heading {
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.3;
    margin-bottom: 1.5rem
}

@media(min-width:768px) {
    .heading {
        font-size: 1.75rem
    }
}

.img {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-end
}

.img img {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 1.125rem
}

.title {
    line-height: 1.3;
    font-weight: 700;
    margin: 0
}

.title a {
    color: inherit
}

.dropdown-btn {
    transition: opacity .5s ease
}

.open>.dropdown-menu {
    display: block !important
}

.open>.dropdown-btn {
    opacity: .5
}

.dropdown {
    position: relative
}

.dropdown-menu {
    display: none;
    position: absolute;
    z-index: 101;
    left: 0;
    border-radius: .75rem;
    font-size: .875rem;
    margin-top: .25rem;
    background-color: var(--modal-bg);
    font-weight: 400;
    box-shadow: 0 .5rem 4rem -.5rem rgba(0, 0, 0, .1), 0 .25rem 1.5rem 0 rgba(0, 0, 0, .1), 0 0 0 1px rgba(0, 0, 0, .05)
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

.dropdown-form {
    min-width: 240px;
    padding: 1.25rem
}

.collapse {
    display: none
}

.collapse.in {
    display: block
}

tr.collapse.in {
    display: table-row
}

tbody.collapse.in {
    display: table-row-group
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition-duration: .35s;
    transition-timing-function: ease
}

/* .fade {
    opacity: 0;
    transition: opacity .15s linear
}

.fade.in {
    opacity: 1
} */

/* .modal-open {
    overflow: hidden !important;
    padding-right: 0px;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    min-height: 100%
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal-footer {
    padding: 1.5rem;
    text-align: right;
    border-top: 1px solid var(--line)
}

.modal-title {
    font-size: 1.25rem;
    margin-bottom: 2rem
}

.modal-head {
    border-radius: inherit;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 1px solid var(--line)
}

.modal-head .modal-title {
    margin: 0
}

.modal-header .modal-title {
    margin-bottom: 0
}

.modal-header+.modal-body {
    padding-top: 0 !important
}

.modal-content {
    position: relative;
    background-color: var(--modal-bg);
    width: 100%;
    outline: 0
}

.modal-body,
.modal-head,
.modal-header {
    padding: 1.5rem
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: rgba(0, 0, 0, .3)
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.in {
    opacity: 1
}

.modal-dialog .close,
.modal-close {
    position: absolute;
    right: 0;
    top: 0;
    color: inherit;
    padding: .5rem;
    width: 2.5rem;
    height: 2.5rem;
    border: 0;
    outline: none !important;
    pointer-events: auto;
    background: 0 0;
    transition: color .15s ease-out
}

.modal .modal-dialog>.close,
.modal-close {
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    z-index: 1
}

.modal-dialog .close {
    opacity: .5
}

.closes svg {
    display: block
}

.modal-title {
    margin-bottom: 1rem
}

.modal-close {
    color: #fff;
    transition: opacity .15s ease-out
}

@media(min-width:992px) {
    .modal-close:hover {
        opacity: .8
    }

    .modal-dialog .close:hover {
        opacity: .8
    }

    .modal {
        -webkit-overflow-scrolling: touch
    }
}

@media(min-width:576px) {
    .modal.fade .modal-dialog {
        transform: scale(.85);
        transition: transform .2s ease, opacity .2s ease
    }

    .modal.in .modal-dialog {
        transform: scale(1)
    }

    .modal-content {
        pointer-events: auto;
        background-clip: padding-box;
        max-width: 26rem;
        min-height: 0;
        border-radius: 1.125rem;
        box-shadow: 0 1rem 2rem -.5rem rgba(0, 0, 0, .25), 0 0 0 1px rgba(0, 0, 0, .1)
    }

    .container .modal-content {
        box-shadow: 0 1rem 3.25rem -.25rem rgba(0, 73, 150, .125), 0 0 0 1px rgba(0, 73, 150, .1)
    }

    .modal-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        margin: 1rem auto;
        min-height: calc(100% - 2rem);
        padding-left: .5rem;
        padding-right: .5rem
    }

    .modal-body,
    .modal-head,
    .modal-header {
        padding: 2rem
    }
}

@media(max-width:575px) {
    .modal-dialog {
        display: flex
    }

    .modal-body {
        padding-top: 3.5rem
    }
} */

html.ovb {
    overflow: hidden;
    width: 100%;
    height: 100%
}

html.ovb .videobox.open {
    opacity: 1
}

.videobox {
    opacity: 0;
    transition: opacity .15s linear;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, .3)
}

.videobox.open {
    display: block !important
}

.videobox .video-content {
    width: 100%;
    height: 100%;
    max-width: none !important;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem
}

.videobox .video-wrap {
    width: 100%;
    max-width: 60rem;
    pointer-events: auto
}

.videobox .video-body {
    position: relative;
    padding: 0;
    background-color: #000;
    box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, .6);
    border-radius: 1.125rem
}

.videobox .video-body .close {
    position: absolute;
    right: 0;
    top: 0;
    margin: -1.25rem -1.25rem 0 0;
    z-index: 1;
    background-color: var(--lcolor);
    box-shadow: 0 0 2rem 0 var(--lcolor);
    color: #fff;
    width: 2.5rem;
    height: 2.5rem;
    padding: .5rem;
    border-radius: 50%;
    border: 0
}

.videobox .video-body .close svg {
    display: block
}

.video_block {
    position: relative;
    border-radius: 1.125rem;
    overflow: hidden
}

.video_block img {
    display: block;
    max-height: 80vh
}

.video_wrap {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%
}

.video_wrap iframe {
    overflow: hidden;
    width: 100%;
    height: 100%
}

.btn-group .btn-block {
    width: 100%
}

.btn-reset {
    vertical-align: top;
    padding: 0;
    margin: 0;
    background: 0 0;
    border: 0;
    cursor: pointer
}

.btn,
.bbcodes,
.ui-button {
    height: 2.5rem;
    line-height: 1.5rem;
    padding: .5rem 1rem;
    border-radius: 1.25rem;
    font-size: .875rem;
    white-space: nowrap;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    vertical-align: top;
    border: 0;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    text-decoration: none !important;
    color: #fff !important;
    transition: box-shadow ease .15s, background-color ease .15s, transform ease .1s, opacity ease .1s
}

.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center
}

.btn-icon svg {
    margin-right: .25rem
}

.btn-border {
    color: inherit !important;
    box-shadow: inset 0 0 0 1px rgba(76, 203, 112, .5);
    background: 0 0
}

.btn-border:hover {
    box-shadow: inset 0 0 0 3px rgba(76, 203, 112, .3)
}

.btn-complaint {
    position: relative
}

.btn-complaint a {
    color: inherit !important
}

.btn-complaint a::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.btn-telegram {
    color: #31a8db !important
}

.btn-telegram>span {
    display: none
}

@media(max-width:767px) {
    .btn-telegram {
        height: auto;
        padding: 0
    }
}

@media(min-width:768px) {
    .btn-telegram .c-icon {
        min-width: 1.5rem;
        max-width: 1.5rem;
        height: 1.5rem;
        padding: .25rem
    }

    .btn-telegram .c-icon svg {
        width: 1rem;
        height: 1rem
    }

    .btn-telegram {
        display: inline-flex;
        align-items: center;
        padding: .5rem;
        background-color: rgba(49, 168, 219, .1)
    }

    .btn-telegram>span {
        display: block;
        padding: 0 .5rem 0 .75rem
    }
}

.bbcodes,
.ui-button,
.qq-upload-button {
    background-color: rgba(76, 203, 112, .1);
    color: var(--lcolor) !important;
    box-shadow: none !important
}

.btn:active,
.bbcodes:active,
.ui-button:active,
.qq-upload-button:active {
    opacity: .8
}

@media(min-width:992px) {

    .bbcodes:hover,
    .ui-button:hover,
    .qq-upload-button:hover {
        background-color: rgba(76, 203, 112, .2)
    }

    .btn-telegram {
        transition: background-color .2s ease
    }

    .btn-telegram:hover {
        background-color: rgba(49, 168, 219, .2)
    }
}

@media(max-width:575px) {
    .btn-block {
        width: 100%
    }

    .btn-block+.btn-block {
        margin-top: .5rem
    }
}

@media(min-width:576px) {
    .btn-group {
        display: flex
    }

    .btn-group .btn+.btn {
        margin-left: .75rem
    }
}

@media(min-width:768px) {
    .btn-lg {
        border-radius: 1.5rem;
        font-size: 1rem;
        height: 3rem !important;
        padding: .75rem 1.25rem !important
    }
}

.form-submit {
    margin-top: 1.5rem
}

.form-group,
.form-check,
.form-combo {
    margin-top: 1.25rem;
    margin-bottom: 0 !important
}

.form-group:first-of-type,
.form-check:first-of-type,
.form-combo:first-of-type {
    margin-top: 0 !important
}

.form-group>label {
    display: block;
    font-size: .75rem;
    line-height: 1.2;
    margin-bottom: .5rem;
    text-transform: uppercase;
    opacity: .8
}

.form-sep {
    border-top: 1px solid rgba(0, 0, 0, .05)
}

label.form-group {
    color: var(--tcolor);
    display: block;
    line-height: 1.2;
    font-size: .75rem
}

label.form-group>.c-muted {
    display: block;
    margin-bottom: .5rem;
    font-weight: 700;
    text-transform: uppercase
}

label.form-group a.f-right {
    padding: .5rem;
    margin: -.5rem
}

@media(min-width:576px) {
    .form-submit {
        margin-top: 2rem
    }

    .form-group,
    .form-check,
    .form-combo {
        margin-top: 1.5rem;
        margin-bottom: 0 !important
    }

    .form-combo {
        display: flex;
        margin-left: -1rem;
        margin-right: -1rem
    }

    .form-combo .form-group {
        width: 100%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 0 1rem;
        margin: 0 !important
    }

    label.form-group,
    .form-group>label {
        margin-bottom: .75rem
    }
}

@media(min-width:576px) and (max-width:767px) {
    .form-combo {
        margin-left: -.5rem;
        margin-right: -.5rem
    }

    .form-combo .form-group {
        padding: 0 .5rem
    }
}

.loginform-foot {
    display: flex
}

.loginform-foot>a:first-child {
    margin-right: auto
}

.check-login {
    display: flex
}

.check-login input {
    margin-right: .5rem
}

.check-login .btn {
    text-transform: uppercase;
    font-size: .75rem
}

select,
textarea,
input[type=text],
input[type=password],
input[type=file],
input[type=datetime],
input[type=datetime-local],
input[type=date],
input[type=month],
input[type=time],
input[type=week],
input[type=number],
input[type=email],
input[type=url],
input[type=search],
input[type=tel],
input[type=color] {
    display: inline-block;
    width: 100%;
    height: 2.5rem;
    line-height: 1.5rem;
    font-size: 1rem;
    color: inherit;
    padding: calc(.5rem - 1px) .75rem;
    background: var(--form-control-bg);
    border: 1px solid var(--form-control-brd);
    border-radius: .375rem;
    outline: none !important;
    box-shadow: 0 .125rem .75rem 0 rgba(23, 43, 61, .05);
    transition: border .2s linear 0s, box-shadow .2s linear 0s;
    background-clip: padding-box
}

select#category {
    width: 100% !important
}

select:focus,
textarea:focus,
input:focus {
    border-color: var(--form-control-brd-f);
    box-shadow: 0 .125rem .75rem 0 rgba(23, 43, 61, .1)
}

::placeholder {
    color: var(--placeholder);
    opacity: 1
}

::-moz-placeholder {
    color: var(--placeholder);
    opacity: 1
}

::-webkit-input-placeholder {
    color: var(--placeholder);
    opacity: 1
}

textarea {
    margin: 0;
    overflow: auto;
    vertical-align: top;
    resize: vertical
}

input[type=radio],
input[type=checkbox] {
    line-height: normal;
    margin: -2px 6px 0 0;
    vertical-align: middle
}

input[type=file],
input[type=image],
input[type=submit],
input[type=reset],
input[type=button],
input[type=radio],
input[type=checkbox] {
    width: auto
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

select[multiple],
select,
input[type=search] {
    -webkit-appearance: none;
    appearance: none
}

select[multiple],
select[size],
textarea {
    height: auto;
    min-height: 6em
}

select:not([multiple]) {
    padding-right: 2rem;
    background-position: calc(100% - .375rem) 50%;
    background-size: 1.5rem 1.5rem;
    background-repeat: no-repeat;
    background-image: var(--select-arrow)
}

input:focus {
    outline: none
}

input[type=file],
input[type=image] {
    height: auto;
    width: 100%;
    padding: .25rem
}

.dle-promt-text {
    width: 100% !important
}

.tableform {
    width: 100%
}

.sep-xfield {
    margin-top: 1.5rem
}

.grecaptcha-badge {
    opacity: 0;
    pointer-events: none
}

.emoji {
    max-width: auto !important;
    max-height: 1.5rem !important;
    border: none;
    vertical-align: middle
}

#dofullsearch,
.comments_subscribe,
#comment-editor>br {
    display: none !important
}

.item {
    position: relative
}

.item-link::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 2
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media(min-width:576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media(min-width:768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media(min-width:992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

#loading-layer {
    display: block !important;
    background-color: var(--loading-bg);
    z-index: 9999 !important;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    overflow: hidden;
    white-space: nowrap
}

#loading-layer:before,
#loading-layer:after {
    content: "";
    width: 64px;
    height: 64px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -32px;
    margin-top: -32px
}

#loading-layer:before {
    border: 4px solid rgba(76, 203, 112, .2);
    border-radius: 50%
}

#loading-layer:after {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 100% 100%;
}

#loading-layer:after {
    animation: load_rotate .8s infinite linear
}

@keyframes load_rotate {
    100% {
        transform: rotate(360deg)
    }
}

.soc_login {
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    margin-bottom: 2rem;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.soc_login_icons {
    display: flex;
    margin-top: 1rem
}

.soc_login_icons a {
    color: inherit;
    cursor: pointer;
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: .5rem;
    padding: .625rem;
    border-radius: 50%;
    background-color: rgba(50, 50, 50, .05)
}

.soc_login_icons a:last-child {
    margin-right: 0
}

.soc_register {
    text-align: left;
    border-top: 0;
    max-width: 300px;
    padding: 0;
    margin: 1.75rem 0
}

.soc_register .soc_login_icons {
    margin-top: 1rem
}

@media(min-width:992px) {
    .soc_login_icons a:hover {
        color: #fff;
        transition: background-color ease .2s, color linear 0s
    }

    .soc_login_icons .soc_vk:hover {
        background-color: #48729e
    }

    .soc_login_icons .soc_ya:hover {
        background-color: red
    }

    .soc_login_icons .soc_fb:hover {
        background-color: #3b5998
    }

    .soc_login_icons .soc_gp:hover {
        background-color: #4284f4
    }

    .soc_login_icons .soc_od:hover {
        background-color: #eb722e
    }

    .soc_login_icons .soc_mail:hover {
        background-color: #005ff9
    }
}

img.fr-dii {
    margin-left: 0 !important;
    margin-right: 0 !important;
    max-width: 100% !important
}

img.fr-dii.fr-fil {
    float: left;
    margin: 5px 24px 25px 0;
    max-width: calc(100% - 5px)
}

.title_quote {
    font-size: .9rem;
    color: rgba(0, 0, 0, .5);
    font-weight: 700;
    font-style: italic;
    border: 0
}

.quote,
blockquote {
    padding: 1.5rem 0;
    border: 0 solid #fede4a;
    border-width: 2px 0;
    font-family: Georgia, serif;
    font-style: italic;
    text-align: center;
    font-size: 1.2em
}

.comment .quote,
.comment blockquote {
    font-size: 1em
}

.comment blockquote,
.comment .quote {
    padding: 10px 16px;
    border-width: 0 0 0 2px;
    background-color: rgba(0, 0, 0, .03);
    text-align: left
}

.comment .title_quote {
    padding-top: 8px;
    padding-bottom: 0;
    padding-left: 16px;
    border-left: 2px solid #fede4a;
    background-color: rgba(0, 0, 0, .03)
}

@media(min-width:576px) {
    .title_quote {
        padding: 4px 16px;
        padding-left: 26px;
        margin-left: 46px;
        border-left: 2px solid #fede4a;
        background-color: rgba(0, 0, 0, .03)
    }

    .quote,
    blockquote {
        margin-left: 46px;
        padding: 10px 26px;
        border-width: 0 0 0 2px;
        background-color: rgba(0, 0, 0, .03);
        position: relative;
        text-align: left
    }

    .quote::before,
    blockquote::before {
        content: "\201c";
        color: #fede4a;
        font-size: 75px;
        line-height: 60px;
        position: absolute;
        left: 0;
        top: 0;
        margin-left: -46px;
        font-style: normal
    }

    .comment .quote,
    .comment blockquote {
        margin-left: 32px
    }

    .comment .quote::before,
    .comment blockquote::before {
        font-size: 60px;
        line-height: 50px;
        margin-left: -32px
    }

    .comment .title_quote {
        padding-left: 16px;
        margin-left: 32px
    }
}

.spoiler {
    background-color: var(--spoiler);
    padding: 1rem;
    padding-left: 3rem;
    border-radius: .75rem;
    font-size: .875rem
}

.spoiler .title_spoiler {
    padding: 1rem;
    padding-left: 3rem;
    margin: -1rem;
    margin-left: -3rem;
    border-radius: 0;
    background: 0 0 !important;
    position: relative
}

.spoiler .title_spoiler a::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.spoiler .title_spoiler img {
    max-width: 1.5rem;
    margin-left: -2rem
}

.text_spoiler {
    display: none
}

.text_spoiler::before {
    content: "";
    display: block;
    height: 1rem
}

.title_spoiler {
    display: flex;
    align-items: center;
    font-weight: 700;
    padding: .5rem;
    background-color: var(--spoiler);
    border-radius: .75rem;
    font-size: .875rem
}

.title_spoiler a {
    color: inherit !important
}

.title_spoiler img {
    width: 1.5rem;
    height: 1.5rem;
    display: block
}

.scriptcode {
    color: #4c6d0f;
    text-align: left;
    font-family: courier new
}

.hide {
    background-color: #f0f0f0;
    padding: 5px;
    color: #f97330;
    margin: 0 0 1em
}

.hide a {
    text-decoration: underline
}

.hide a:hover {
    text-decoration: none
}

table.userstop {
    font-size: 80%
}

.userstop td,
.pm td.pm_list,
.pm td.pm_head {
    border-bottom: 1px solid var(--line);
    padding: 12px 2px
}

table.pm,
table.userstop {
    width: 100%;
    margin-bottom: 0
}

table.pm select {
    width: 150px
}

.userstop thead td,
.pm td.pm_head {
    border-bottom: 1px solid var(--line);
    font-weight: 700
}

table.pm .navigation {
    border-top-width: 0;
    margin: 0
}

.table_wide {
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    padding: .5rem 0;
    margin: -.5rem 0;
    word-break: break-all;
    -webkit-overflow-scrolling: touch
}

.table_wide>table {
    min-width: 800px;
    table-layout: fixed
}

.table_wide table.pm {
    min-width: 600px
}

.highslide-wrapper,
.highslide-outline {
    background: #fff
}

.highslide-image {
    border: 2px solid #fff
}

.highslide-active-anchor {
    visibility: hidden
}

.highslide-active-anchor img {
    visibility: hidden
}

.highslide-dimming {
    background-color: #000
}

.highslide-html {
    background-color: #fff
}

.highslide-loading {
    display: block;
    color: #fff;
    font-size: 9px;
    font-weight: 700;
    text-decoration: none;
    padding: 3px;
    border: 1px solid #fff;
    background-color: #000
}

a.highslide-full-expand {
    background: url(/public/dleimages/fullexpand.gif) no-repeat;
    display: block;
    margin: 0 10px 10px 0;
    width: 34px;
    height: 34px
}

.highslide-display-block {
    display: block
}

.highslide-display-none {
    display: none
}

.highslide-caption {
    display: none;
    padding: 5px;
    background: #fff
}

.highslide-controls {
    width: 195px;
    height: 40px;
    background: url(/public/dleimages/controlbar-black-border.gif) no-repeat 0 -90px;
    margin-right: 15px;
    margin-bottom: 10px;
    margin-top: 10px
}

.highslide-controls ul {
    position: relative;
    left: 15px;
    height: 40px;
    list-style: none;
    margin: 0;
    padding: 0;
    background: url(/public/dleimages/controlbar-black-border.gif) no-repeat 100% -90px
}

.highslide-controls li {
    float: left;
    padding: 5px 0
}

.highslide-controls a {
    background: url(/public/dleimages/controlbar-black-border.gif);
    display: block;
    float: left;
    height: 30px;
    width: 30px;
    outline: none
}

.highslide-controls a.disabled {
    cursor: default
}

.highslide-controls a span {
    display: none
}

.highslide-controls .highslide-previous a {
    background-position: 0 0
}

.highslide-controls .highslide-previous a:hover {
    background-position: 0 -30px
}

.highslide-controls .highslide-previous a.disabled {
    background-position: 0 -60px !important
}

.highslide-controls .highslide-play a {
    background-position: -30px 0
}

.highslide-controls .highslide-play a:hover {
    background-position: -30px -30px
}

.highslide-controls .highslide-play a.disabled {
    background-position: -30px -60px !important
}

.highslide-controls .highslide-pause a {
    background-position: -60px 0
}

.highslide-controls .highslide-pause a:hover {
    background-position: -60px -30px
}

.highslide-controls .highslide-next a {
    background-position: -90px 0
}

.highslide-controls .highslide-next a:hover {
    background-position: -90px -30px
}

.highslide-controls .highslide-next a.disabled {
    background-position: -90px -60px !important
}

.highslide-controls .highslide-move a {
    background-position: -120px 0
}

.highslide-controls .highslide-move a:hover {
    background-position: -120px -30px
}

.highslide-controls .highslide-full-expand a {
    background-position: -150px 0
}

.highslide-controls .highslide-full-expand a:hover {
    background-position: -150px -30px
}

.highslide-controls .highslide-full-expand a.disabled {
    background-position: -150px -60px !important
}

.highslide-controls .highslide-close a {
    background-position: -180px 0
}

.highslide-controls .highslide-close a:hover {
    background-position: -180px -30px
}

#modal-overlay {
    background: #000 !important;
    opacity: .5 !important
}

.ui-widget-overlay {
    background: #000;
    opacity: .5;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed
}

.ui-helper-clearfix:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden
}

.ui-helper-clearfix {
    display: inline-block
}

* html .ui-helper-clearfix {
    height: 1%
}

.ui-helper-clearfix {
    display: block
}

.ui-dialog {
    text-align: left;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 420px;
    max-width: calc(100% - 1rem) !important;
    max-height: none !important;
    z-index: 999;
    border-radius: 1.125rem;
    box-shadow: 0 .75rem 3rem -.5rem rgba(0, 0, 0, .3);
    border: 1px solid rgba(0, 0, 0, .05);
    background-color: var(--modal-bg);
    background-clip: padding-box
}

.ui-dialog-titlebar {
    padding: 1.25rem;
    display: flex;
    align-items: center;
    min-width: 0;
    position: relative;
    border-bottom: 1px solid var(--line)
}

.ui-dialog-title {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 700;
    font-size: 1rem
}

.ui-dialog-titlebar-close {
    height: 2rem;
    width: 2rem;
    margin: -.5625rem;
    margin-left: auto;
    padding: 0;
    text-indent: -9999px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    border: 0 !important;
    background: 0 0 !important;
    box-shadow: none !important
}

.ui-button-icon-space {
    display: none
}

.ui-dialog-titlebar-close .ui-icon {
    width: 1rem;
    height: 1rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -.5rem 0 0 -.5rem;
    background-size: 100% auto;
    opacity: 1
}

@media(min-width:992px) {
    .ui-dialog-titlebar-close .ui-icon {
        transition: opacity .2s ease
    }

    .ui-dialog-titlebar-close:hover .ui-icon {
        opacity: .8
    }
}

.ui-icon {
    overflow: hidden;
    text-indent: -9999px
}

.ui-dialog label {
    color: #575757
}

.ui-dialog-content {
    padding: 1.25rem;
    overflow: auto;
    position: relative;
    zoom: 1
}

.loginbox.ui-dialog,
.loginbox .ui-dialog-content {
    overflow: visible !important
}

.ui-dialog-content h2 {
    display: inline;
    font-size: 1em;
    font-weight: 700
}

.ui-dialog .ui-dialog-buttonpane {
    padding: 20px;
    text-align: center;
    border-top: 1px solid var(--line)
}

.ui-dialog .ui-resizable-se {
    bottom: 3px;
    height: 14px;
    right: 3px;
    width: 14px
}

.ui-draggable .ui-dialog-titlebar {
    cursor: move
}

.ui-helper-hidden-accessible {
    display: none
}

.ui-dialog-buttonset .ui-button {
    margin-top: .125rem;
    margin-bottom: .125rem
}

@media(min-width:576px) {
    .ui-dialog-buttonset .ui-button+.ui-button {
        margin-left: .25rem
    }
}

@media(max-width:575px) {
    .ui-dialog {
        position: fixed !important;
        width: 100% !important;
        left: .5rem !important;
        right: .5rem !important
    }

    .ui-dialog-buttonset .ui-button {
        width: 100%
    }

    .ui-dialog-buttonset .ui-button+.ui-button {
        margin-top: .25rem
    }
}

@media(max-width:500px) {
    .modal-body {
        padding: 1.125rem;
        padding-top: 2rem
    }

    .form-group>label {
        font-size: .75rem
    }
}

.dle-popup-userprofile .ui-dialog-content {
    padding: 0
}

.popup_userinfo_top {
    padding: 2rem;
    padding-bottom: 0;
    height: 4.5rem;
    margin-bottom: 3.5rem;
    background: var(--spoiler)
}

.popup_userinfo_top .avatar {
    vertical-align: top;
    border-radius: 50%;
    border: .25rem solid #fede4a;
    width: 5rem;
    height: 5rem;
    box-shadow: 0 .75rem 1.5rem 0 rgba(0, 0, 0, .1)
}

.popup_userinfo_top .avatar img {
    background-color: #eaeaea
}

.popup_userinfo.useronline .avatar {
    border-color: var(--lcolor)
}

.popup_userinfo .usinf {
    padding: 1rem 2rem;
    font-size: .875rem
}

.usinf {
    list-style: none;
    padding: 0;
    margin: 0
}

.usinf>li {
    padding: .5rem 0;
    border-top: 1px solid var(--line)
}

.usinf>li:first-child {
    border-top-width: 0
}

.ui-c1,
.ui-c2 {
    display: block
}

@media(max-width:575px) {
    .ui-c1 {
        font-size: .75rem;
        opacity: .5
    }
}

@media(min-width:576px) {

    .ui-c1,
    .ui-c2 {
        display: inline-block;
        vertical-align: top
    }

    .ui-c1 {
        width: 40%;
        margin-right: 5%
    }

    .ui-c2 {
        width: 50%;
        font-weight: 700
    }
}

@media(min-width:768px) {
    .usinf {
        font-size: 1.1rem
    }

    .ui-c1 {
        width: 30%
    }

    .ui-c2 {
        width: 60%
    }
}

.entry-listpage+.alert {
    margin-top: 2rem
}

.alert {
    margin-bottom: 2rem;
    font-size: .875rem
}

.alert-line,
.alert_in {
    background-color: #fff9db;
    color: #765846;
    box-shadow: 0 .5rem 1.5rem -.25rem rgba(0, 0, 0, .12)
}

.alert_in {
    padding: 1.125rem;
    border-radius: 1.125rem
}

.alert-cont,
.alert-cont p {
    margin: 0
}

.alert-title {
    display: flex;
    align-items: center;
    font-weight: 700;
    margin-bottom: .5rem
}

.alert-title>i,
.alert-line .i__info {
    margin-right: .5rem;
    color: #fede4a
}

.alert-line {
    padding: 1.125rem 0;
    font-size: .75rem;
    font-weight: 700;
    position: relative;
    z-index: 100
}

.alert-line .wrp-min {
    display: flex;
    align-items: center
}

.alert-line .wrp-min>.i__info {
    align-self: flex-start
}

.alert-line .wrp-min .alert-cont {
    align-self: center;
    padding: .125rem 0
}

.social-links {
    display: flex;
    margin-bottom: 15px;
}

.social-links a {
    opacity: .9;
    min-width: 2rem;
    height: 2rem;
    text-align: center;
    flex-grow: 1;
    flex-shrink: 1;
    background-color: #f5f6f7;
    border-radius: .375rem
}

.social-links a:not(:first-child) {
    margin-left: 1rem
}

@media(min-width:992px) {
    .social-links a {
        transition: opacity .2s ease, box-shadow .2s ease
    }

    .social-links a:hover {
        opacity: 1;
        box-shadow: inset 0 0 0 1px rgba(0, 0, 0, .05)
    }
}

.social-links a.soc-vk {
    background-color: #4a76a8
}

.social-links a.soc-fb {
    background-color: #4267b2
}

.social-links a.soc-ok {
    background-color: #ef863a
}

.social-links a.soc-gl {
    background-color: #f5f6f7
}

.modal-head .social-links {
    margin-top: 1rem
}