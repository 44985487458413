.page-sys {
    margin-top: 2rem
}

.page-user #dle-content .alert-line {
    display: none !important
}

.userpage_head {
    position: relative;
    overflow: hidden;
    border-radius: 1.125rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    padding: 2rem;
    border-bottom: 1px solid var(--line)
}

.userpage-main-info {
    text-align: center
}

.userpage-status {
    display: inline-block;
    padding: .375rem .75rem;
    border: 1px solid var(--line);
    line-height: 1rem;
    border-radius: 1rem
}

.userpage-status * {
    color: inherit !important;
    font-size: .75rem;
    text-transform: uppercase
}

.userpage-main-circle {
    margin: 0 auto;
    margin-top: 2rem;
    width: 8.75rem;
    height: 8.75rem;
    border: 2px solid #fede4a;
    border-radius: 50%;
    padding: 1rem;
    position: relative;
    z-index: 1
}

.userpage_head .avatar.fit-cover {
    width: 100%;
    height: 100%;
    box-shadow: 0 .75rem 1.5rem 0 rgba(0, 0, 0, .15)
}

.userpage_head .avatar.fit-cover img {
    background-color: #eaeaea
}

.userpage-main-circle>svg {
    width: 640px;
    height: 640px;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: -1;
    margin-left: -320px;
    margin-top: -320px;
    opacity: .24;
    pointer-events: none
}

.user_status {
    position: absolute;
    top: 50%;
    left: 100%;
    height: 26px;
    line-height: 26px;
    margin-top: -13px;
    margin-left: 2rem;
    white-space: nowrap
}

.user_status::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: .5rem;
    background-color: #172b3d
}

.userpage_head.online .user_status::before {
    background-color: #4ccb70;
    opacity: 1
}

.btn.user_edit {
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-width: 0;
    margin-right: 2rem;
    padding: .5rem;
    min-width: 2.5rem;
    width: 2.5rem;
    height: 2.5rem
}

.btn.user_edit svg {
    width: 1.5rem;
    height: 1.5rem;
    display: block
}

.userpage-main-names {
    position: relative;
    z-index: 1;
    margin-top: 2rem
}

.userpage-main-names .title {
    font-size: 1.5rem;
    line-height: 1.3
}

.uh_info_btn {
    position: relative;
    text-align: center
}

.uh_info_btn>.btn {
    width: 100%;
    position: relative;
    z-index: 1;
    margin-bottom: 0
}

.userpage-addon {
    margin: -.25rem;
    display: flex;
    flex-wrap: wrap;
    text-align: center
}

.userpage-addon>li {
    width: 100%;
    padding: .25rem
}

@media(max-width:499px) {

    .userpage-addon>li:nth-child(3),
    .userpage-addon>li:nth-child(4) {
        max-width: 50%;
        flex: 0 0 50%
    }
}

@media(min-width:500px) {
    .userpage-addon>li {
        max-width: 50%;
        flex: 0 0 50%
    }
}

@media(min-width:640px) {
    .userpage-addon {
        margin: -.5rem
    }

    .userpage-addon>li {
        padding: .5rem
    }
}

.userpage-addon .item {
    border-radius: .75rem;
    padding: .75rem;
    background-color: var(--spoiler)
}

.userpage-addon-foot {
    margin-top: 2rem;
    text-align: center
}

.userinfo_status {
    font-size: .75rem
}

.page-user #dle-content .alert {
    margin-top: 2rem
}

#fullsearch {
    width: 100%;
    padding: .25rem
}

@media(min-width:640px) {
    #fullsearch {
        padding: .5rem
    }
}

@media(min-width:1040px) {
    #fullsearch {
        padding: 1rem
    }
}

.search-box {
    padding: 1.5rem;
    border-radius: 1.125rem
}

.search-box-field {
    position: relative
}

.search-box-field #searchinput {
    height: 3.5rem;
    font-size: 1.125rem;
    border: 0;
    box-shadow: 0 .25rem 1.25rem 0 rgba(0, 0, 0, .15);
    padding: 1rem;
    padding-right: 4rem
}

.search-box-btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 3.5rem;
    height: 3.5rem;
    padding: 1rem;
    border: 0;
    background: 0 0
}

.search-box-btn svg {
    display: block;
    color: #4ccb70
}

@media(min-width:992px) {
    .search-box-btn svg {
        transition: color .2s ease
    }

    .search-box-btn svg:hover {
        color: #289b7f
    }
}

.search_result_num {
    font-size: .75rem;
    font-weight: 700;
    margin-top: .75rem;
    margin-bottom: -.75rem;
    color: #fff;
    opacity: .5
}