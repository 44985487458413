#comment-editor .bb-pane,
#dlereplypopup .bb-pane {
    display: none
}

#dle-comments-list {
    margin-top: -.25rem
}

.highslide img {
    cursor: zoom-in;
    cursor: -moz-zoom-in;
    cursor: -webkit-zoom-in;
}

.highslide-viewport-size {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0
}

.comment {
    margin-top: .25rem;
    padding: 1.5rem;
    background-color: var(--spoiler);
    position: relative
}

.comment-head {
    position: relative;
    font-size: .875rem;
    min-height: 2.5rem;
    padding-left: 3.25rem;
    margin-top: -.25rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column
}

@media(min-width:640px) {
    #dle-comments-list {
        margin-top: -.5rem
    }

    .comment {
        margin-top: .5rem;
        border-radius: .75rem
    }

    .comment-head {
        padding-bottom: 1.25rem;
        padding-right: 9.25rem;
        margin-bottom: 1.25rem
    }

    .comment-head::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        margin: 0 -1.5rem;
        height: 0;
        border-bottom: 1px solid var(--line)
    }
}

.comment-head .name {
    font-weight: 700;
    margin-top: -.25rem;
    margin-bottom: .125rem
}

.comment-head .name a {
    color: inherit
}

.comment-head .name a:hover {
    color: #4ccb70
}

.comment-meta {
    display: flex;
    align-items: center;
    font-size: .75rem
}

.comment-meta .group-label {
    margin-right: .75rem
}

.group-label {
    background-color: #172b3d;
    font-size: .625rem;
    color: #fff !important;
    font-weight: 700;
    height: 1.25rem;
    line-height: 1rem;
    padding: .125rem .5rem;
    border-radius: 1rem
}

.group-label span {
    color: inherit !important
}

.group-label.g-adm {
    background-color: #30a7da
}

.group-label.g-guest {
    background-color: rgba(23, 43, 61, .5)
}

.com-likes {
    display: flex;
    text-align: center;
    font-size: .75rem;
    font-weight: 700
}

.com-likes a,
.comment-tools a {
    color: var(--coms-meta)
}

.com-like-plus svg {
    color: #4ccb70
}

.com-like-minus svg {
    color: #f9563d
}

.comment-foot {
    margin-top: 1rem
}

.comment-tools {
    display: flex;
    font-size: .875rem;
    font-weight: 700;
    margin: -.25rem
}

.comment-tools a {
    display: flex;
    align-items: center;
    padding: .25rem
}

.comment-title {
    font-size: 1.125rem;
    line-height: 1.3
}

.comment-title a {
    color: inherit
}

.comment-text {
    word-wrap: break-word
}

@media(max-width:639px) {
    .com__reply>a>span {
        display: none
    }

    .com-like-plus,
    .com-like-minus,
    .comment-foot {
        display: flex;
        align-items: center
    }

    .comment-tools {
        margin-right: auto
    }

    .com-likes a {
        margin-left: 1rem
    }

    .com-like-plus,
    .com-like-minus {
        padding: .25rem;
        margin: -.25rem
    }

    .com-likes svg {
        margin-right: .25rem
    }
}

@media(min-width:640px) {
    .com__reply {
        margin-right: auto
    }

    .com-likes {
        position: absolute;
        right: 0;
        top: 0;
        margin-top: .5rem;
        margin-right: .25rem
    }

    .com-likes svg {
        display: block;
        margin: 0 auto .25rem;
        opacity: .8;
        transition: opacity .2s ease
    }

    .com-likes a {
        padding: .5rem .25rem;
        width: 4.5rem
    }
}

@media(min-width:992px) {
    .com-likes a:hover svg {
        opacity: 1
    }

    .comment-tools a:hover,
    .com-likes a:hover {
        color: var(--coms-meta-h)
    }

    .comment-title a:hover {
        color: #4ccb70
    }
}

.comment-head .avatar-status {
    position: absolute;
    left: 0
}

.avatar-status::after {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    pointer-events: none;
    background-color: #172b3d;
    width: .375rem;
    height: .375rem;
    border-radius: 50%;
    margin: .1875rem
}

.avatar-status.online::after {
    background-color: #4ccb70;
    content: ""
}

.avatar-status {
    position: relative;
    width: 2.5rem;
    height: 2.5rem
}

.avatar-status>label,
.avatar-status>label i {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center
}

.avatar-status>label {
    position: absolute;
    left: 0;
    top: 0;
    cursor: pointer;
    z-index: 1;
    margin: 0
}

.avatar-status>label+i>a {
    pointer-events: none
}

.avatar-status>label input {
    position: absolute;
    opacity: 0;
    visibility: hidden
}

.avatar-status>label i {
    color: #fff;
    opacity: 0;
    background-color: rgba(76, 203, 112, .8);
    transition: opacity .2s ease
}

.avatar-status>label i svg {
    display: block;
    transform: translateY(.25rem);
    transition: transform .2s ease
}

.avatar-status>label input:checked~i {
    opacity: 1
}

.avatar-status>label input:checked~i svg {
    transform: translateY(0)
}

.avatar-status .avatar.fit-cover {
    width: 100%;
    height: 100%
}

.avatar-status .avatar.fit-cover img[src*="noavatar.png"] {
    background-color: #eaeaea
}

.comment.pos-comm {
    background-color: rgba(76, 203, 112, .1)
}

.comment.pos-comm .comment-head::after {
    border-bottom-color: rgba(76, 203, 112, .12)
}

.comment.pos-comm .avatar-status .avatar.fit-cover img[src*="noavatar.png"] {
    background-color: #dcf0e1
}

.comment.neg-comm {
    background-color: rgba(249, 86, 61, .1)
}

.comment.neg-comm .comment-head::after {
    border-bottom-color: rgba(249, 86, 61, .12)
}

.comment.neg-comm .avatar-status .avatar.fit-cover img[src*="noavatar.png"] {
    background-color: #f2d2cd
}

.comments-tree-list .comment {
    margin-top: 0
}

.comments-tree-list .comments-tree-list {
    padding-left: .75rem
}

.comments-tree-list .comments-tree-list .comments-tree-item:first-child .comment {
    border-top-left-radius: 0
}

.comments-tree-item {
    margin-top: .25rem
}

.comments-tree-list .comments-tree-list .comments-tree-item {
    margin-top: .25rem
}

.mass_comments_action {
    display: flex;
    font-size: .75rem;
    align-items: center;
    margin-top: 2rem
}

.mass_comments_action select {
    margin: 0 .5rem
}

@media(min-width:640px) {
    .comments-tree-item {
        margin-top: .5rem
    }

    .comments-tree-list .comments-tree-list {
        padding-left: 1.5rem
    }

    .comments-tree-list .comments-tree-list .comment-head::after {
        display: none
    }

    .comments-tree-list .comments-tree-list .comment-head {
        margin-bottom: 0
    }
}