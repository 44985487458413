.background {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    overflow: hidden
}

.background>i {
    position: absolute;
    z-index: -1;
    display: block
}

.bg-clouds {
    width: 100%;
    display: flex !important;
    justify-content: center
}

.cloud-c1 {
    fill: var(--cloud-c1);
    opacity: .1
}

.cloud-c2 {
    fill: var(--cloud-c2)
}

.darktheme .cloud-c1 {
    opacity: 1
}

@media(min-width:768px) {

    .bg-circle-yellow,
    .bg-circle-purple,
    .bg-circle-green {
        width: 75rem;
        height: 75rem
    }

    .bg-circle-purple {
        background-image: radial-gradient(closest-side, rgba(155, 84, 232, 0.2) 0, rgba(155, 84, 232, 0) 100%)
    }

    .bg-circle-yellow {
        background-image: radial-gradient(closest-side, rgba(254, 222, 74, 0.2) 0, rgba(254, 222, 74, 0) 100%)
    }

    .bg-circle-green {
        background-image: radial-gradient(closest-side, rgba(76, 203, 112, 0.2) 0, rgba(76, 203, 112, 0) 100%)
    }

    .bg-style-1 .bg-circle-yellow.bgc-1 {
        top: 0;
        right: 50%;
        transform: translate(10%, -60%)
    }

    .bg-style-1 .bg-circle-yellow.bgc-2 {
        top: 50%;
        right: 50%;
        transform: translate(-5%, -50%)
    }

    .bg-style-1 .bg-circle-purple {
        top: 70%;
        left: 70%;
        transform: translate(0%, -50%)
    }

    .bg-style-1 .bg-circle-green {
        top: 5%;
        left: 75%;
        transform: translate(0%, 0%)
    }

    .bg-style-1 .bg-clouds svg,
    .bg-style-2 .bg-clouds svg {
        max-width: 2880px;
        min-width: 2880px;
        height: auto
    }

    .bg-style-2 .bg-circle-purple {
        top: 75%;
        left: 50%;
        transform: translate(50%, -50%)
    }

    .bg-style-2 .bg-circle-yellow.bgc-1 {
        bottom: 0;
        left: 50%;
        transform: translate(10%, 50%)
    }

    .bg-style-2 .bg-circle-yellow.bgc-2 {
        top: 30%;
        right: 50%;
        transform: translate(-5%, -50%)
    }

    .bg-style-2 .bg-circle-green {
        top: 15%;
        left: 50%;
        transform: translate(60%, 0%)
    }

    .bg-style-2 .bg-clouds {
        bottom: 0;
        left: 0
    }

    .darktheme .bg-style-1 .bg-circle-yellow,
    .darktheme .bg-style-1 .bg-circle-purple,
    .darktheme .bg-style-1 .bg-circle-green,
    .darktheme .bg-style-2 .bg-circle-yellow,
    .darktheme .bg-style-2 .bg-circle-purple,
    .darktheme .bg-style-2 .bg-circle-green {
        display: none !important
    }
}

@media(max-width:767px) {

    .bg-style-1 .bg-clouds svg,
    .bg-style-2 .bg-clouds svg {
        max-width: 2000px;
        min-width: 2000px;
        height: 570px;
        margin-left: 22rem
    }
}

.page-cat-bg {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    position: relative
}

.page-cat-bg .footer {
    margin-top: auto
}

.page-head-cat {
    background-color: #3bb375;
    z-index: 0;
    position: relative;
    background-image: linear-gradient(0deg, #3BB375 0%, #289B7F 100%);
    padding-top: 5.5rem;
    margin-top: -5.5rem;
    padding-bottom: 5rem;
    color: #fff
}

html.darktheme .page-head-cat {
    background-color: var(--dark-section-bg);
    background-image: var(--dark-section-grad)
}

.page-head-cat .wrp-min {
    padding-top: 2rem
}

.page-cat-cont {
    margin-top: -4rem;
    margin-bottom: 2rem
}

html.darktheme .page-head-cat .bg-clouds {
    display: none !important
}

@media(max-width:767px) {
    .page-head-cat {
        border-radius: 1.75rem;
        border-top-right-radius: 0;
        border-top-left-radius: 0
    }
}

@media(min-width:768px) {
    .page-head-cat {
        padding-top: 6.5rem;
        margin-top: -6.5rem;
        padding-bottom: 5.5rem
    }
}

@media(min-width:992px) {
    .page-cat-bg .background {
        display: block !important
    }

    .page-head-cat .bg-clouds {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: -1;
        opacity: .4;
        background-image: url(/public/images/green_cloud.svg);
        background-size: 2560px auto;
        background-repeat: repeat-y;
        background-position: 50% 100%
    }
}

@media(min-width:1040px) {
    .page-head-cat .wrp-min {
        padding-top: 3.5rem
    }

    .page-cat-cont {
        margin-bottom: 3.5rem
    }
}

.head-cat-title {
    display: flex;
    align-items: center
}

.head-cat-title .title {
    font-size: 1.5rem
}

.head-cat-tools {
    margin-left: auto;
    padding-left: 1rem
}

@media(min-width:500px) {
    .head-cat-title .title {
        font-size: 2rem
    }
}

@media(max-width:640px) {
    .head-cat-title {
        padding-left: .5rem;
        padding-right: .5rem
    }
}

@media(min-width:1040px) {
    .head-cat-title .title {
        font-size: 2.5rem
    }
}

.cat-menu-btn {
    background-color: rgba(23, 43, 61, .8);
    color: #fff;
    border: 0;
    padding: .5rem;
    border-radius: 50%;
    position: relative
}

.cat-menu-btn svg {
    display: block;
    transition: transform .2s ease, opacity .2s ease
}

.cat-menu-btn::after {
    content: "";
    height: 0;
    border-top: 2px solid;
    width: 1.5rem;
    border-radius: 2px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -1px 0 0 -.75rem;
    opacity: 0;
    transition: opacity .3s ease
}

.cat-menu-btn:not(.collapsed) svg {
    transform: scale(1, .2);
    opacity: 0
}

.cat-menu-btn:not(.collapsed)::after {
    opacity: 1
}

.page-head-cat.darkmod {
    background-color: var(--dark-section-bg);
    background-image: var(--dark-section-grad)
}

.page-head-cat.darkmod .bg-clouds {
    background-image: url(/public/images/dark_cloud.svg)
}

html.darktheme .page-head-cat.darkmod .bg-clouds {
    display: none !important
}

.cat-menu-list::before {
    content: "";
    display: block;
    height: 1rem
}

.cat-menu-list ul {
    display: flex;
    flex-wrap: wrap;
    margin: -.125rem
}

.cat-menu-list ul>li {
    padding: .125rem
}

.cat-menu-list ul>li a {
    display: block;
    padding: .375rem .5rem;
    border-radius: .375rem;
    background-color: var(--cat-menu);
    color: #fff;
    font-weight: 700;
    font-size: .75rem
}

.cat-menu-list ul>li.active a {
    cursor: default;
    background-color: #4ccb70 !important;
    box-shadow: 0 .5rem 1.5rem -.25rem rgba(0, 0, 0, .25);
    position: relative
}

@media(max-width:639px) {
    .cat-menu-list {
        padding-left: .5rem;
        padding-right: .5rem
    }
}

@media(min-width:992px) {
    .cat-menu-list ul>li a {
        transition: background-color .2s ease
    }

    .cat-menu-list ul>li a:hover {
        background-color: var(--cat-menu-h)
    }

    .cat-menu-btn {
        transition: color .2s ease
    }

    .cat-menu-btn:hover {
        color: #fede4a
    }
}

.footer {
    margin-top: auto;
    position: relative;
    bottom: 0;
}

.footer-panel {
    background-color: var(--footer-bg);
    color: #fff;
    border-radius: 1.75rem;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding: 1rem;
    position: relative;
    font-size: .875rem
}

@media(min-width:768px) {
    .footer-panel {
        border-radius: 2.25rem
    }
}

.upper {
    display: block;
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    color: #4ccb70;
    padding: .75rem
}

.upper svg {
    display: block
}

.copyright {
    padding: .75rem 0;
    line-height: 1.4
}

.f-telegram a {
    display: flex;
    align-items: center;
    color: #7cceff;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 1.4
}

.f-telegram a .c-icon {
    min-width: 2rem;
    max-width: 2rem;
    height: 2rem;
    padding: .25rem
}

.f-telegram a .c-icon svg {
    width: 1.5rem;
    height: 1.5rem
}

@media(max-width:575px) {
    .footer-panel {
        text-align: center
    }

    .f-telegram a {
        flex-direction: column
    }

    .f-telegram a .c-icon {
        margin-bottom: .5rem
    }

    .copyright {
        max-width: 13.5rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        margin-top: -1rem
    }
}

@media(min-width:576px) {
    .footer-panel {
        padding: 1rem 5rem
    }

    .footer-panel .logotype,
    .footer-panel .upper {
        position: absolute;
        top: 0;
        margin: 1rem
    }

    .footer-panel .logotype {
        display: block !important;
        padding: .375rem;
        left: 0
    }

    .footer-panel .upper {
        right: 0
    }

    .copyright {
        font-weight: 700
    }

    .f-telegram a .c-icon {
        margin-right: 1rem
    }
}

@media(max-width:991px) {
    .footer-panel {
        padding-bottom: 4.5rem
    }
}

@media(min-width:768px) {
    .footer-cont .copyright {
        flex: 1 1 auto;
        display: flex;
    }

    .footer-cont {
        height: 3rem;
        display: flex;
        align-items: center
    }
}

@media(min-width:992px) {
    .footer-panel {
        height: 5rem
    }
}

.header {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 3.5rem;
    pointer-events: none
}

.header-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
    height: 3.5rem;
    pointer-events: auto;
    background-color: var(--header-bg);
    border-radius: 1.75rem;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: 0 .125rem .5rem 0 rgba(0, 0, 0, .03), 0 .125rem 2rem -.5rem rgba(23, 43, 61, .2)
}

@media(min-width:768px) {
    .header {
        height: 4.5rem
    }

    .header-panel {
        padding: 1rem;
        height: 4.5rem;
        border-radius: 2.25rem
    }
}

@media(max-width:991px) {

    .header .wrp-min,
    .footer .wrp-min {
        padding-left: 0;
        padding-right: 0
    }
}

.logotype {
    color: inherit !important;
    padding: .125rem .5rem;
    display: inline-block;
    vertical-align: top
}

.logotype svg {
    display: block
}

.logo-icon {
    max-width: 2.25rem;
    overflow: hidden;
    display: block
}

.log-in,
.q-search-call {
    display: inline-block;
    vertical-align: top;
    border-radius: 50%;
    border: 0;
    background: 0 0;
    cursor: pointer;
    height: 2.5rem;
    min-width: 2.5rem;
    max-width: 2.5rem;
    padding: .5rem
}

.log-in {
    background: rgba(76, 203, 112, .1);
    color: #4ccb70;
    transition: background .2s ease
}

.log-in svg,
.q-search-call svg {
    display: block
}

@media(min-width:992px) {
    .head-tools-panel {
        margin: 0 auto;
        max-width: 38rem
    }

    .head-tools {
        flex: 1 1 auto;
        display: block !important;
        opacity: 1 !important
    }

    .hmenu {
        padding-right: 2.5rem
    }

    .hmenu,
    .hmenu>a>span {
        display: flex;
        align-items: center
    }

    .hmenu>a {
        width: 100%;
        max-width: 25%;
        flex: 0 0 25%;
        font-size: .875rem;
        line-height: 1.5rem;
        padding: .25rem;
        font-weight: 700;
        color: inherit;
        transition: color .2s ease
    }

    .hmenu>a .c-icon {
        min-width: 2rem;
        max-width: 2rem;
        height: 2rem;
        padding: .25rem;
        margin-right: .75rem;
        transition: transform .2s ease;
        position: relative
    }

    .hmenu>a.active .c-icon {
        transform: scale(1) !important
    }

    .hmenu>a:hover .c-icon {
        transform: scale(.9)
    }

    a.hm-games:hover {
        color: var(--menu-hover-games)
    }

    a.hm-apps:hover {
        color: var(--menu-hover-apps)
    }

    a.hm-top:hover {
        color: var(--menu-hover-top)
    }

    a.hm-news:hover {
        color: var(--menu-hover-news)
    }

    .hmenu-more-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2rem;
        border: 0;
        line-height: 1rem;
        padding: .5rem;
        border-radius: 1rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: transparent;
        background-image: var(--hmenu-more-grad)
    }

    .hmenu-more-dots {
        width: 1rem;
        height: .25rem;
        display: flex;
        justify-content: space-between;
        transition: width .2s ease
    }

    .hmenu-more-dots>i {
        width: .25rem;
        height: .25rem;
        background-color: var(--tcolor);
        transition: background-color .2s ease;
        display: inline-block;
        border-radius: 50%
    }

    .hmenu-more:hover .hmenu-more-btn {
        background-image: linear-gradient(90deg, rgba(76, 203, 112, 0) 0%, rgba(76, 203, 112, 0.1) 100%)
    }

    .hmenu-more:hover .hmenu-more-btn .hmenu-more-dots {
        width: 1.25rem
    }

    .hmenu-more:hover .hmenu-more-btn .hmenu-more-dots>i {
        background-color: #4ccb70
    }

    .hmenu-more .dropdown-menu {
        display: block !important;
        left: 50%;
        width: 15rem;
        margin-left: -7.5rem;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-.5rem);
        transition: opacity .2s ease .15s, visibility 0s linear .15s, transform .2s ease .15s
    }

    .hmenu-more:hover .dropdown-menu {
        opacity: 1;
        visibility: visible;
        transform: translateY(0)
    }

    .hmenu-order>a {
        border-bottom: 1px solid var(--line);
        font-size: 1rem
    }

    .hmenu-sub {
        padding: 1rem 0
    }

    .hmenu-sub>a {
        color: inherit;
        display: block;
        line-height: 1.5rem;
        padding: .25rem 1.25rem
    }

    .hmenu-sub>a:hover {
        color: var(--lcolor)
    }

    .head-right {
        min-width: 7.5rem;
        margin-left: auto
    }

    .head-right>* {
        float: right;
        margin-left: .5rem
    }

    .log-in:hover {
        background: rgba(76, 203, 112, .2)
    }
}

a.hm-games.active .s-yellow {
    box-shadow: 0 .25rem .5rem 0 rgba(251, 205, 66, .3), 0 0 0 .375rem rgba(251, 205, 66, .3)
}

a.hm-apps.active .s-purple {
    box-shadow: 0 .25rem .5rem 0 rgba(146, 72, 225, .3), 0 0 0 .375rem rgba(146, 72, 225, .3)
}

a.hm-top.active .s-red {
    box-shadow: 0 .25rem .5rem 0 rgba(255, 89, 64, .3), 0 0 0 .375rem rgba(255, 89, 64, .3)
}

a.hm-news.active .s-blue {
    box-shadow: 0 .25rem .5rem 0 rgba(55, 169, 228, .3), 0 0 0 .375rem rgba(55, 169, 228, .3)
}

.hmenu-more .dropdown-menu {
    display: block !important
}

.hmenu-order {
    display: block;
    position: relative;
    padding: 0 1.25rem
}

.hmenu-order>a {
    display: block;
    line-height: 1.75rem;
    padding: 1rem 0;
    height: 3.75rem;
    font-weight: 700
}

.hmenu-order>a::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 1
}

.hmenu-order>svg {
    position: absolute;
    right: 1.25rem;
    top: 50%;
    margin-top: -1rem
}

@media(max-width:991px) {
    .header-panel .logotype {
        margin: 0 auto
    }

    .menu-butter {
        order: -1;
        display: block !important;
        padding: .625rem;
        border: 0;
        background: 0 0;
        color: #4ccb70
    }

    .butterbrod {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column
    }

    .butterbrod>i {
        width: 100%;
        height: 0;
        border-top: 4px solid;
        border-radius: 3px
    }

    .head-tools {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 9999;
        width: 100%;
        min-height: 100%;
        overflow: hidden;
        opacity: 0;
        transition: opacity ease .2s;
        overflow-y: auto;
        background-color: rgba(0, 0, 0, .3);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px)
    }

    .mm-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1
    }

    .head-tools.open,
    .head-tools.open .mm-overlay,
    .q-search,
    .hmenu-sub,
    .menu-close {
        display: block !important
    }

    .head-tools-panel {
        width: 100%;
        padding: 2rem;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: var(--modal-bg);
        box-shadow: 0 .5rem 1.5rem -.25rem rgba(0, 0, 0, .1);
        border-radius: 1.75rem;
        transition: transform ease .4s
    }

    .head-tools-panel::before {
        content: "";
        display: block;
        height: 2.5rem;
        order: -1
    }

    .menu-close {
        position: absolute;
        top: 0;
        right: 0;
        margin: 1rem;
        width: 2.5rem;
        height: 2.5rem;
        padding: .5rem
    }

    .menu-close svg {
        display: block
    }

    .q-search {
        order: -1;
        margin-bottom: 2rem;
        padding-top: 1.5rem
    }

    .q-search-label {
        position: absolute;
        bottom: 100%;
        width: 100%;
        text-align: center;
        opacity: .5;
        text-transform: uppercase;
        font-size: .75rem
    }

    .hmenu {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem
    }

    .hmenu>a {
        width: 100%;
        max-width: 50%;
        flex: 0 0 50%;
        padding: .5rem;
        font-weight: 700;
        color: inherit !important
    }

    .hmenu>a span {
        display: block;
        text-align: center;
        padding: .75rem;
        border-radius: 1.125rem
    }

    .hmenu>a .c-icon {
        display: block;
        margin: 0 auto;
        margin-bottom: .5rem
    }

    a.hm-games>span {
        background-color: rgba(254, 216, 74, .1)
    }

    a.hm-apps>span {
        background-color: rgba(151, 79, 229, .1)
    }

    a.hm-top>span {
        background-color: rgba(251, 96, 72, .1)
    }

    a.hm-news>span {
        background-color: rgba(55, 164, 227, .1)
    }

    .hmenu-more {
        width: 100%;
        margin-top: 1rem;
        padding: .5rem
    }

    .hmenu-more .dropdown-menu {
        position: static;
        border: 0 !important;
        background: 0 0 !important;
        box-shadow: none !important
    }

    .hmenu-order {
        background-color: rgba(76, 203, 112, .1);
        border-radius: 1.125rem
    }

    .hmenu-more-btn {
        display: none !important
    }

    .hmenu-sub {
        margin-top: 2rem
    }

    .hmenu-sub a {
        display: block;
        text-align: center;
        padding: .25rem;
        color: inherit;
        font-size: .875rem;
        opacity: .5
    }

    .toolbar {
        transition: transform ease .4s
    }

    html.mm body {
        overflow: hidden
    }

    html.mm .head-tools.open {
        opacity: 1
    }

    html.mm .head-tools.open .head-tools-panel {
        transform: translateY(0%)
    }

    html.mm .toolbar {
        transform: translateY(100%)
    }
}

@media(min-width:640px) and (max-width:991px) {
    .head-tools {
        padding: 2rem
    }

    .head-tools-panel {
        max-width: 30rem;
        margin: 0 auto;
        transform: translateY(2rem)
    }
}

@media(max-width:639px) {
    .head-tools {
        padding-right: 2rem
    }

    .head-tools-panel {
        min-height: 100%;
        max-width: 24rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        transform: translateX(-2rem)
    }

    .hmenu {
        margin: -.25rem
    }

    .hmenu>a,
    .hmenu-more {
        padding: .25rem
    }
}

.q-search-box {
    position: relative
}

.q-search-btn {
    position: absolute;
    right: 0;
    top: 0;
    border: 0;
    transition: color .2s ease;
    width: 2.5rem;
    height: 2.5rem;
    padding: .5rem;
    color: inherit !important;
    background: 0 0
}

.q-search-btn:hover {
    color: #4ccb70 !important
}

.q-search-btn svg {
    display: block
}

.q-search-text {
    border-radius: 1.25rem !important;
    padding-left: 1rem !important;
    padding-right: 3rem !important
}

@media(min-width:992px) {
    .q-search.open {
        display: block !important
    }

    .q-search {
        margin-top: -2.5rem;
        opacity: 0;
        transform: translateY(1rem)
    }

    .hmenu,
    .q-search,
    .q-search-call>svg {
        transition: transform .2s ease, opacity .2s ease
    }

    .hmenu {
        transform: translateY(0);
        opacity: 1
    }

    .q-search-label {
        display: none
    }

    .q-search-call {
        display: block !important;
        color: inherit
    }

    .q-search-call:hover {
        opacity: .8
    }

    .q-search-call>svg.qs-2 {
        opacity: 0;
        transform: scale(.8);
        margin-top: -100%
    }

    .qs .q-search {
        transform: translateY(0);
        opacity: 1
    }

    .qs .hmenu {
        transform: translateY(-1rem);
        opacity: 0;
        pointer-events: none
    }

    .qs .q-search-call {
        background-color: rgba(23, 43, 61, .05)
    }

    .qs .q-search-call>svg.qs-1 {
        opacity: 0;
        transform: scale(.8)
    }

    .qs .q-search-call>svg.qs-2 {
        opacity: 1;
        transform: scale(1)
    }
}

#searchsuggestions {
    z-index: 102;
    border-radius: .75rem;
    background: var(--searchsug);
    padding: .5rem;
    width: 17.5rem;
    font-size: .875rem;
    box-shadow: 0 .5rem 4rem -.5rem rgba(0, 0, 0, .1), 0 .25rem 1.5rem 0 rgba(0, 0, 0, .1), 0 0 0 1px rgba(0, 0, 0, .05);
    overflow: hidden;
    overflow-y: auto;
    max-height: 20rem;
    -webkit-overflow-scrolling: touch
}

@media(min-width:992px) {
    #searchsuggestions {
        position: fixed !important;
        width: 37rem;
        left: 50% !important;
        top: 3.5rem !important;
        transform: translateX(-50%) !important
    }
}

#searchsuggestions:hover {
    display: block !important
}

#searchsuggestions a,
#searchsuggestions span.notfound {
    padding: .75rem 1.25rem;
    display: block;
    text-decoration: none;
    border-radius: .5rem
}

#searchsuggestions a+a {
    margin-top: .125rem
}

#searchsuggestions a {
    color: inherit;
    background-color: var(--searchsug-item)
}

#searchsuggestions a .searchheading {
    transition: color .2s ease
}

#searchsuggestions a:hover .searchheading {
    color: #4ccb70
}

#searchsuggestions a span {
    display: block;
    cursor: pointer
}

#searchsuggestions span.searchheading {
    display: block;
    font-weight: 700;
    margin-bottom: .2em
}

#searchsuggestions span.seperator a {
    padding: 10px 0;
    text-align: center;
    border: 0;
    background-color: transparent;
    color: #919191
}

#searchsuggestions .break,
#searchsuggestions span.seperator {
    display: none !important
}

.speedbar-panel {
    background-color: #289b7f;
    z-index: 1;
    position: relative;
    background-image: linear-gradient(90deg, #4CCB70 0%, #30A478 100%);
    box-shadow: 0 .25rem 1.5rem 0 rgba(0, 0, 0, .15);
    margin-top: -2.5rem;
    padding-top: 2.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-weight: 700;
    color: rgba(255, 255, 255, .5);
    font-size: .75rem;
    border-bottom-left-radius: 1.75rem;
    border-bottom-right-radius: 1.75rem
}

.speedbar-panel>span {
    display: block;
    line-height: 1.25rem;
    padding: .375rem 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.speedbar-panel a {
    color: #fff
}

.speedbar-panel a:hover {
    color: rgba(255, 255, 255, .8)
}

@media(max-width:991px) {
    .wrp-min.speedbar {
        padding: 0 !important
    }
}

@media(min-width:768px) {
    .speedbar-panel {
        float: left;
        max-width: calc(100% - 2.5rem);
        padding-left: 1.5rem;
        padding-right: 1rem;
        border-bottom-left-radius: 2.25rem;
        border-bottom-right-radius: 1.125rem
    }
}

.anchor-line {
    overflow: hidden;
    height: 0
}

.anchor-line>span {
    margin-top: -4.5rem;
    display: block
}

.page-head-main {
    text-align: center;
    margin-top: -4.5rem;
    padding-top: 4.5rem;
    position: relative;
    pointer-events: none
}

.page-head-main>.title {
    font-size: 1.5rem;
    line-height: 1.3;
    max-width: 14rem;
    margin: 2rem auto;
    color: var(--main-heading);
    pointer-events: auto
}

.page-head-main>img {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, -7%);
    width: 50rem;
    height: auto;
    pointer-events: none
}

.section {
    margin-bottom: 2rem
}

.section-title,
.section-head,
.static-page .title {
    margin-bottom: 1rem
}

.section-head {
    display: flex;
    align-items: center
}

.section-head .section-title {
    margin-bottom: 0;
    margin-right: auto
}

.section-title {
    font-size: 1rem;
    line-height: 1.3;
    display: flex;
    align-items: center
}

.static-page .title {
    font-size: 1.25rem;
    line-height: 1.3
}

.section-title>.c-icon {
    margin-right: 1rem
}

.btn-all+.btn-all {
    display: none !important
}

@media(min-width:500px) {
    .page-head-main>.title {
        font-size: 2rem;
        max-width: 22rem;
        margin: 3rem auto
    }

    .page-head-main>img {
        width: 70rem
    }
}

@media(max-width:639px) {
    .btn-all {
        padding: .25rem;
        width: 2rem;
        height: 2rem
    }

    .btn-all>span {
        display: none
    }

    .btn-all>svg {
        display: block;
        width: 1.5rem;
        height: 1.5rem
    }
}

@media(min-width:640px) {
    .section-title {
        font-size: 1.25rem
    }

    .btn-all svg {
        display: none !important
    }
}

@media(min-width:768px) {
    .page-head-main+.section {
        margin-top: -2rem
    }
}

@media(min-width:992px) {

    .section-title,
    .static-page .title {
        font-size: 1.5rem
    }
}

@media(min-width:1040px) {
    .page-head-main>.title {
        font-size: 2.5rem;
        max-width: 27rem;
        margin: 4rem auto
    }

    .page-head-main>img {
        width: 88rem
    }

    .section {
        margin-bottom: 3.5rem
    }

    .section-title,
    .section-head,
    .static-page .title {
        margin-bottom: 1.5rem
    }
}

.dark-section {
    color: #fff;
    background-color: var(--dark-section-bg);
    position: relative;
    margin-top: auto;
    z-index: 0
}

.dark-foot {
    padding-top: 2rem
}

@media(max-width:767px) {
    .dark-foot {
        border-radius: 1.75rem;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }
}

@media(min-width:1040px) {
    .dark-foot {
        padding-top: 3.5rem
    }

    .dark-foot .background {
        margin-top: -1.5rem;
        display: block !important
    }

    .dark-foot .bg-clouds svg {
        max-width: 2550px;
        min-width: 2550px;
        height: auto
    }

    .dark-foot .bg-circle-green {
        top: 1.5rem;
        left: 50%;
        transform: translate(0%, 0%)
    }
}

.dark-head {
    padding-top: 5.5rem;
    margin-top: -5.5rem
}

@media(min-width:768px) {
    .dark-head {
        padding-top: 6.5rem;
        margin-top: -6.5rem
    }
}

@media(min-width:992px) {
    .dark-head .background {
        display: block !important;
        margin-bottom: -1.5rem
    }

    .dark-head .bg-clouds {
        bottom: 0;
        z-index: 1
    }

    .dark-head .bg-clouds svg {
        max-width: 2550px;
        min-width: 2550px;
        height: auto
    }

    .dark-head .bg-circle-green {
        bottom: 5%;
        left: 50%;
        transform: translate(0%, -30%);
        z-index: 1
    }
}

.block {
    background-color: var(--block-bg);
    border-radius: 1.125rem;
    margin-top: 1rem
}

.view-app .block+.alert-line {
    margin-top: 1rem;
    box-shadow: none;
    border-radius: 1.125rem;
    padding: .75rem;
    font-size: .875rem
}

.block-list>.block:first-child {
    margin-top: 0
}

.dark-section .block {
    background-color: var(--block-dark-bg)
}

.block img {
    max-width: 100%
}

.b-cont,
.b-head,
.b-icon-title {
    padding: 1.5rem
}

.b-title {
    font-size: 1rem;
    line-height: 1.3;
    font-weight: 700
}

.b-cont video,
.b-cont iframe {
    max-width: 100%;
    vertical-align: top;
    margin-top: 1rem
}

.b-head {
    display: flex;
    align-items: center
}

.b-head .section-title {
    margin-bottom: 0;
    margin-right: auto
}

.b-head .btn {
    margin-left: .5rem
}

@media(max-width:639px) {
    .wrp-min.block-list {
        padding-left: 0;
        padding-right: 0
    }

    .b-add-info {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important
    }

    .b-comments .b-head .btn-all svg {
        transform: rotate(90deg)
    }

    .b-comments .b-cont {
        padding-left: 0;
        padding-right: 0
    }
}

@media(min-width:640px) {

    .b-cont,
    .b-head,
    .b-icon-title {
        padding: 2rem
    }

    .b-title {
        font-size: 1.25rem
    }

    .b-head .btn {
        margin-left: 1rem
    }
}

@media(min-width:992px) {
    .b-title {
        font-size: 1.5rem
    }
}

@media(min-width:1040px) {
    .block {
        margin-top: 2rem
    }

    .view-app .block+.alert-line {
        margin-top: 2rem
    }
}

.b-icon-title {
    padding-bottom: 0;
    margin-bottom: 1.5rem;
    position: relative;
    text-align: center
}

.b-icon-title .c-icon {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%)
}

.b-icon-title .b-title {
    text-transform: uppercase;
    margin-top: .5rem;
    margin-bottom: 0
}

.b-icon-title .c-icon+.b-title {
    margin-top: .25rem !important
}

.b-icon-title+.b-cont {
    padding-top: 0
}

.b-tabs {
    padding: 0 .5rem;
    overflow: hidden;
    display: flex;
    font-weight: 700
}

@media(min-width:640px) {
    .b-tabs {
        padding: 0 1rem
    }
}

.b-tabs+.b-cont,
.b-head+.b-cont {
    padding-top: 0
}

.b-tabs>a {
    height: 4.5rem;
    line-height: 1.5rem;
    padding: 1.5rem 1rem;
    position: relative
}

.b-tabs>a:not(.active) {
    color: inherit !important
}

.b-tabs>a::after {
    content: "";
    height: 0;
    border-top: .5rem solid;
    border-radius: .25rem;
    position: absolute;
    left: 1rem;
    right: 1rem;
    top: 0;
    margin-top: -.25rem;
    opacity: 0;
    transform: translateY(-.5rem);
    background-color: #4ccb70;
    transition: opacity .2s ease, transform .2s ease
}

.b-tabs>a.active::after {
    opacity: 1;
    transform: translateY(0)
}

.navigation {
    display: flex;
    width: 100%;
    margin-top: 2rem;
    position: relative;
    text-align: center
}

@media(min-width:992px) {
    .entry-listpage>.wrp-min {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-bottom: 1rem;
        max-width: 61rem
    }

    .entry-listpage>.wrp-min>.navigation {
        margin-top: 1rem
    }
}

@media(min-width:640px) {
    .entry-listpage>.wrp-min {
        padding-left: .5rem;
        padding-right: .5rem;
        padding-bottom: .5rem
    }

    .entry-listpage>.wrp-min>.navigation {
        margin-top: 1.5rem
    }
}

@media(max-width:639px) {
    .entry-listpage>.wrp-min {
        padding-left: .25rem;
        padding-right: .25rem;
        padding-bottom: .25rem
    }

    .entry-listpage>.wrp-min>.navigation {
        margin-top: 1.75rem
    }

    .b-comments .b-cont .navigation {
        margin-top: 1.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
}

.navigation a {
    color: inherit
}

.pages,
.navigation svg {
    display: block
}

.pages {
    border-radius: 1.25rem;
    min-width: 0;
    flex: 1 1 auto;
    display: flex;
    justify-content: center
}

.pages-list {
    display: flex;
    margin: 0 auto
}

.pages-list>*,
.page_prev>*,
.page_next>* {
    display: block;
    line-height: 1.5rem;
    height: 2.5rem;
    font-weight: 700;
    padding: .5rem;
    border-radius: 2rem;
    transition: background-color ease .2s, color ease .2s
}

.page_prev {
    margin-right: .5rem
}

.page_next {
    margin-left: .5rem
}

.page_prev>a,
.page_next>a {
    background-color: #45c368;
    color: #fff;
    background-image: linear-gradient(180deg, #4CCB70 0%, #3DBA60 100%);
    box-shadow: 0 .25rem .5rem 0 rgba(69, 195, 104, .3)
}

.page_prev>span,
.page_next>span {
    opacity: .5;
    color: #fff;
    background-color: rgba(23, 43, 61, .2)
}

@media(max-width:767px) {
    .pages-list {
        margin: -1.5rem 0;
        padding: 1.5rem 0;
        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .pages-list span:not(.nav_ext) {
        color: #4ccb70
    }

    .pages-list::after,
    .pages-list::before {
        content: "";
        max-width: 1rem;
        min-width: 1rem;
        height: 1rem
    }

    .pages-list>* {
        padding-left: .75rem;
        padding-right: .75rem
    }

    .pages {
        overflow: hidden;
        background-color: var(--block-bg);
        position: relative
    }

    .pages::after,
    .pages::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        width: 2rem;
        height: 100%;
        pointer-events: none;
        border-radius: 1.25rem
    }

    .pages::before {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        left: 0;
        background-image: linear-gradient(90deg, var(--block-bg) 0%, var(--block-bg-transp) 100%)
    }

    .pages::after {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        right: 0;
        background-image: linear-gradient(-90deg, var(--block-bg) 0%, var(--block-bg-transp) 100%)
    }
}

@media(min-width:768px) {
    .pages-list>* {
        margin: 0 .125rem
    }

    .pages-list>*,
    .page_prev>*,
    .page_next>* {
        font-size: 1.125rem;
        min-width: 2.5rem
    }

    .pages-list span:not(.nav_ext) {
        background-color: #f9bd3b;
        color: #fff;
        background-image: linear-gradient(180deg, #FEDE4A 0%, #F8B035 100%);
        box-shadow: 0 .25rem .5rem 0 rgba(251, 205, 66, .3)
    }
}

@media(min-width:992px) {
    .pages-list a:hover {
        background-color: rgba(76, 203, 112, .1);
        color: #4ccb70
    }
}

.page-orders {
    margin-top: 0 !important
}

.order-form {
    background-color: var(--spoiler);
    padding: 1rem;
    border-radius: .5rem;
    margin: 1.5rem 0
}

.order-form .form-submit {
    margin-top: 1rem
}