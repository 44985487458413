@media(min-width:992px) {
    .bg-img-blur {
        position: absolute;
        z-index: -1;
        bottom: 5rem;
        right: 50%;
        margin-right: -22rem;
        transform: translate(-20%, -10%);
        width: 68rem;
        height: 68rem;
        padding: .25rem;
        border-radius: 50%;
        overflow: hidden
    }

    .bg-img-blur .fit-cover,
    .bg-img-blur::after {
        width: 100%;
        height: 100%;
        border-radius: inherit
    }

    .bg-img-blur .fit-cover {
        z-index: -1;
        opacity: .5
    }

    .bg-img-blur::before,
    .bg-img-blur::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0
    }

    .bg-img-blur::after {
        background-image: var(--dark-circle-blur)
    }

    .bg-img-blur::before {
        top: .25rem;
        left: .25rem;
        right: .25rem;
        bottom: .25rem;
        border-radius: inherit;
        background-color: rgba(55, 121, 157, .4);
        backdrop-filter: blur(12px);
        -webkit-backdrop-filter: blur(12px)
    }
}

.view-app {
    margin-bottom: 2rem
}

.view-app-head .wrp-min,
.view-news-head .wrp-min {
    padding-top: 2rem
}

.view-app-head .title,
.view-news-head .title {
    font-size: 1.25rem;
    margin-bottom: 1.25rem
}

.view-app-img {
    width: 100%;
    max-width: 10rem;
    margin-right: 1.5rem
}

.view-app-img .img {
    width: 10rem;
    height: 10rem;
    position: relative;
    z-index: 0
}

.view-app-img .img::after,
.view-app-img .img::before {
    content: "";
    position: absolute;
    z-index: -1
}

.view-app-img .img::after {
    left: 50%;
    top: 50%;
    width: calc(100% + 3rem);
    height: calc(100% + 3rem);
    transform: translate(-50%, -30%);
    background-image: radial-gradient(closest-side, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 100%)
}

.view-app-img .img::before {
    background-size: 1rem 1rem;
    opacity: .5;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nIzE0MjYzNicgZD0nTTIsOGEyLDIsMCwxLDAsMiwyQTIsMiwwLDAsMCwyLDhabTgtOGEyLDIsMCwxLDAsMiwyQTIsMiwwLDAsMCwxMCwwWicvPjwvc3ZnPg==)
}

.view-img-label {
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: -1.25rem;
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.25rem;
    height: 1.5rem;
    padding: .125rem .5rem;
    border-radius: .75rem;
    border-top-left-radius: 0 !important
}

@media(min-width:992px) {
    .view-img-label {
        margin-top: -1.75rem;
        font-size: 1rem;
        line-height: 1.5rem;
        height: 2rem;
        padding: .25rem 1rem;
        border-radius: 1rem
    }
}

@media(min-width:576px) {

    .view-app-head .title,
    .view-news-head .title {
        font-size: 1.5rem
    }
}

@media(min-width:640px) and (max-width:767px) {
    .view-app-data {
        border-top: 1px solid rgba(255, 255, 255, .05);
        padding-top: 1.5rem;
        margin-top: 1rem
    }
}

@media(min-width:640px) {
    .view-app-head .wrp-min {
        padding-bottom: 5.5rem
    }

    .view-app-data {
        display: flex;
        flex-wrap: wrap
    }

    .view-app-data .specs-list {
        width: 100%;
        max-width: calc(100% - 14rem);
        flex: 0 0 calc(100% - 14rem)
    }

    .view-app-rate,
    .view-news-head .likes {
        max-width: 12rem;
        min-width: 12rem;
        margin-left: 2rem
    }
}

@media(max-width:767px) {
    .view-app-head .title {
        text-align: center;
        max-width: 32rem;
        margin-left: auto;
        margin-right: auto
    }

    .view-app-img {
        text-align: center;
        margin-left: auto;
        margin-right: auto
    }

    .view-app-main .btn-group {
        justify-content: center
    }

    .view-app-img .img::before {
        width: 15.75rem;
        height: 100%;
        margin-top: 2rem;
        left: 50%;
        top: 0;
        transform: translateX(-50%)
    }
}

@media(max-width:639px) {
    .view-app-head .wrp-min {
        padding-left: 0;
        padding-right: 0
    }

    .view-app-head .title {
        padding-left: 2rem;
        padding-right: 2rem
    }

    .view-app-main {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
}

@media(min-width:768px) {
    .view-app-main {
        display: flex
    }

    .view-app-head .title,
    .view-news-head .title {
        font-size: 1.75rem;
        margin-bottom: 1.5rem
    }

    .view-app-img .img::before {
        width: 100%;
        height: 80%;
        margin-left: 2rem;
        left: 0;
        top: 50%
    }
}

@media(max-width:991px) {
    .view-app-head {
        background-image: radial-gradient(closest-side, rgba(55, 121, 157, .2) 0, rgba(55, 121, 157, 0) 100%);
        background-size: 35rem 35rem;
        background-position: 50% -20%;
        background-repeat: no-repeat
    }
}

@media(min-width:992px) {
    .view-app-img {
        max-width: 15rem;
        margin-right: 2.5rem
    }

    .view-app-img .img {
        width: 15rem;
        height: 15rem
    }
}

@media(min-width:1040px) {
    .view-app {
        margin-bottom: 3.5rem
    }

    .view-app-head .wrp-min,
    .view-news-head .wrp-min {
        padding-top: 3.5rem;
        padding-bottom: 7rem
    }
}

.view-app-tools {
    position: relative;
    z-index: 1;
    font-size: .875rem;
    margin-top: .5rem
}

.view-app-tools a {
    padding: .5rem;
    display: inline-block;
    vertical-align: top;
    color: inherit
}

.view-app-tools a svg {
    vertical-align: middle;
    margin-top: -.125rem
}

.view-app-tools a>svg+span {
    margin-left: .25rem
}

.view-app-data .specs-list {
    margin-top: -.25rem
}

.spec-icon {
    min-width: 1.25rem;
    max-width: 1.25rem;
    margin-right: .5rem
}

.spec-icon svg {
    display: block
}

.spec-label {
    font-weight: 700
}

.spec-cont {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

@media(max-width:639px) {
    .view-app-rate {
        display: flex;
        flex-direction: column
    }

    .view-app-rate .popularity {
        order: -1;
        margin-top: 0 !important;
        margin-bottom: 1rem
    }

    .rate-nums {
        display: flex;
        justify-content: space-between
    }

    .view-app-data .btn-group>.btn {
        display: block;
        width: 100%;
        margin-top: .5rem
    }

    .specs-list {
        margin: 0 -1.5rem;
        overflow: hidden;
        position: relative
    }

    .specs-list::after,
    .specs-list::before {
        content: "";
        position: absolute;
        top: 0;
        height: 100%;
        width: 1.5rem;
        z-index: 1;
        pointer-events: none
    }

    .specs-list::before {
        left: 0;
        background-image: linear-gradient(90deg, var(--spec-fade) 0%, var(--spec-fade-transp) 100%)
    }

    .specs-list::after {
        right: 0;
        background-image: linear-gradient(-90deg, var(--spec-fade) 0%, var(--spec-fade-transp) 100%)
    }

    .specs-list ul {
        padding: 1.5rem 0;
        margin: -1.5rem 0;
        font-size: .75rem;
        display: flex;
        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .specs-list ul::after,
    .specs-list ul::before {
        content: "";
        display: block;
        min-width: 1rem;
        max-width: 1rem;
        height: 1rem
    }

    .specs-list ul>li {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
        padding: 1.5rem .5rem;
        white-space: nowrap;
        position: relative
    }

    .specs-list ul>li a::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%
    }

    .spec-cont {
        min-width: 5.5rem
    }

    .spec-cont svg {
        width: 1rem;
        height: 1rem
    }

    .spec-cont,
    .spec-label,
    .spec-icon {
        display: block
    }

    .spec-icon {
        margin-bottom: .5rem
    }
}

@media(min-width:640px) {
    .view-app-tools {
        margin-top: 1rem
    }

    .specs-item {
        display: flex;
        align-items: center;
        min-width: 0;
        padding: .375rem 0;
        line-height: 1.5rem
    }

    .spec-label {
        width: 100%;
        max-width: 7rem;
        margin-right: 1.5rem
    }

    .spec-cont svg {
        width: 1.25rem;
        height: 1.25rem
    }
}

@media(min-width:860px) {
    .spec-icon {
        min-width: 1.5rem;
        max-width: 1.5rem;
        margin-right: .75rem
    }

    .specs-item {
        padding: .5rem 0
    }
}

@media(max-width:859px) {
    .specs-list {
        font-size: .875rem
    }

    .spec-icon svg {
        width: 1.25rem;
        height: 1.25rem
    }
}

@media(max-width:991px) {
    .specs-list {
        position: relative;
        z-index: 1
    }
}

.likes {
    display: flex
}

.likes>a:not(:first-child) {
    margin-left: .5rem
}

.likes>a {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    font-weight: 700;
    font-size: .875rem;
    border-radius: .75rem;
    text-align: center;
    position: relative
}

.likes>a>span {
    display: block;
    padding: .5rem;
    transition: opacity .2s ease
}

.likes>a svg {
    display: block;
    width: 2rem;
    height: 2rem;
    margin: 0 auto;
    margin-bottom: .25rem
}

.view-app-rate {
    text-align: center
}

.view-app-rate>*:not(:first-child) {
    margin-top: .75rem
}

.view-app-rate .likes>a {
    color: inherit !important;
    background-color: #142636
}

.likes>a>.like-minus {
    color: #f9563d
}

.likes>a>.like-plus {
    color: #4ccb70
}

@media(min-width:992px) {

    .likes>a>span,
    .view-app-tools a {
        transition: opacity .2s ease
    }

    .likes>a:hover>span,
    .view-app-tools a:hover {
        opacity: .8
    }
}

.rate-nums {
    font-size: .75rem
}

.rating_progress_bar {
    text-align: center;
    background-color: #f9563d;
    font-size: .6875rem;
    text-transform: uppercase;
    line-height: 1rem;
    padding: .25rem;
    border-radius: .75rem;
    position: relative;
    z-index: 0
}

.rating_progress_bar>span {
    background-color: var(--lcolor);
    position: absolute;
    left: 0;
    top: 0;
    border-radius: .75rem;
    height: 100%;
    text-indent: 9999px;
    white-space: nowrap;
    overflow: hidden;
    z-index: -1;
    box-shadow: 0 0 .5rem 0 rgba(74, 201, 110, .3)
}

.view-app-data .btn-group {
    margin-top: 1.5rem;
    width: 100%
}

.view-app-rate .ya-share2 {
    margin-top: 1.5rem;
    height: 1.5rem
}

@media(min-width:860px) {
    .ya-share2::before {
        content: attr(title);
        font-size: .75rem;
        opacity: .5;
        display: block;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: .5rem
    }
}

.view-app-head .b-screens {
    margin-top: 3.5rem
}

.app-video-img {
    position: relative
}

.app-video-img .cover {
    display: block;
    position: relative;
    border-radius: .5rem;
    box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, .25);
    background-color: #273d52;
    max-height: 100%
}

.app-video-img .cover img {
    display: block;
    margin: 0 auto;
    opacity: 0;
    visibility: hidden
}

.video_btn {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    margin: -1rem 0 0 -1rem;
    border: 0;
    background: 0 0;
    padding: .25rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .4);
    color: #fff
}

.video_btn svg {
    display: block
}

@media(max-width:639px) {
    .view-app-head .b-screens {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0
    }
}

@media(max-width:991px) {
    .b-screens .b-icon-title+.b-cont {
        margin-top: -1rem
    }

    .b-screens .b-cont {
        padding: 0;
        padding-bottom: .5rem;
        overflow: hidden
    }

    .screenshots {
        margin: -1.5rem 0;
        padding: 1.5rem 1rem;
        white-space: nowrap;
        text-align: center;
        overflow: hidden;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .screenshots>span,
    .app-video {
        display: inline-block;
        vertical-align: top;
        padding: 1rem .5rem
    }

    .screenshots>span img {
        border-radius: .5rem;
        box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, .25)
    }

    .app-video-img .cover img,
    .screenshots>span img {
        height: 12rem
    }
}

@media(min-width:992px) {
    .screenshots {
        display: flex;
        justify-content: center
    }

    .screenshots {
        padding-bottom: 2.75rem
    }

    .screenshots>span,
    .app-video {
        width: 100%;
        max-width: 25%;
        flex: 0 0 25%;
        position: relative;
        padding: 1.5rem;
        z-index: 0;
        text-align: center
    }

    .screenshots>* {
        display: none
    }

    .screenshots>*:nth-child(-n+4) {
        display: block
    }

    .screenshots>span::after,
    .app-video::after {
        content: "";
        display: block;
        width: 100%;
        padding-top: 100%;
        background-color: #142636;
        border-radius: 50%;
        z-index: -1
    }

    .screenshots>span::before,
    .app-video::before {
        content: "";
        position: absolute;
        left: 50%;
        top: 100%;
        opacity: .3;
        margin-top: .75rem;
        margin-left: -.75rem;
        width: 1.5rem;
        padding-top: 1.5rem;
        z-index: -1;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAyNCAyNCc+PHBhdGggZmlsbD0nI0ZGRkZGRicgZD0nTTE1LDNsMi4zLDIuM2wtMi44OSwyLjg3bDEuNDIsMS40MkwxOC43LDYuN0wyMSw5VjNIMTV6IE0zLDlsMi4zLTIuM2wyLjg3LDIuODlsMS40Mi0xLjQyTDYuNyw1LjNMOSwzSDNWOXogTTksMjEgbC0yLjMtMi4zbDIuODktMi44N2wtMS40Mi0xLjQyTDUuMywxNy4zTDMsMTV2Nkg5eiBNMjEsMTVsLTIuMywyLjNsLTIuODctMi44OWwtMS40MiwxLjQybDIuODksMi44N0wxNSwyMWg2VjE1eicvPjwvc3ZnPg==);
        background-size: 100% 100%
    }

    .screenshots>span img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: .5rem;
        display: block;
        max-width: calc(100% - 1.5rem) !important;
        max-height: calc(100% - 1.5rem) !important;
        width: auto;
        height: auto;
        margin: 0 auto;
        box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, .25);
        transition: margin-top .2s ease
    }

    .screenshots>span:hover img {
        margin-top: -.25rem
    }

    .screenshots>span::before,
    .app-video::before {
        transition: opacity .2s ease
    }

    .screenshots>span:hover::before,
    .app-video:hover::before {
        opacity: .8
    }

    .app-video {
        cursor: pointer
    }

    .app-video-img {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        padding: .75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: transform .2s ease
    }

    .app-video-img .cover img {
        max-width: 100%;
        max-height: 12.25rem
    }

    .app-video:hover .app-video-img {
        transform: translateY(-.25rem)
    }
}

@media(min-width:640px) {
    .view-app-head+.block-list {
        margin-top: -3.5rem;
        position: relative;
        z-index: 1
    }
}

.link-faq {
    background-color: var(--spoiler);
    color: inherit;
    font-weight: 700;
    display: flex;
    align-items: center;
    border-radius: .75rem;
    padding: .75rem 1rem;
    line-height: 1.5rem
}

.link-faq:not(:first-child) {
    margin-top: .5rem
}

.link-faq::after {
    content: "";
    margin-left: auto;
    background-color: var(--lcolor);
    width: .5rem;
    height: .5rem;
    border-radius: 50%;
    text-align: center;
    opacity: 0;
    transform: scale(.5)
}

@media(min-width:992px) {
    .link-faq {
        transition: background-color .7s ease, color .1s ease
    }

    .link-faq:hover {
        color: var(--lcolor);
        background-color: var(--spoiler-h)
    }

    .link-faq::after {
        transition: transform .3s ease, opacity .2s ease
    }

    .link-faq:hover::after {
        opacity: 1;
        transform: scale(1)
    }
}

.b-nobugs {
    display: flex;
    align-items: flex-start;
    padding: 1.5rem
}

.b-nobugs-icon {
    margin-right: .75rem
}

.b-nobugs-icon .c-green {
    width: 2.25rem;
    height: 2.25rem;
    padding: .375rem;
    display: block;
    border-radius: 50%;
    background-color: rgba(76, 203, 112, .1)
}

.b-nobugs-icon .c-green svg {
    display: block
}

@media(max-width:639px) {
    .b-nobugs-icon .img {
        display: none
    }
}

@media(min-width:640px) {
    .b-nobugs {
        padding: 1.5rem 2rem
    }

    .b-nobugs-icon {
        display: flex;
        align-items: center
    }

    .b-nobugs-icon .img {
        width: 2.75rem;
        height: 2.75rem
    }

    .b-nobugs-icon .img img {
        border-radius: .75rem
    }

    .b-nobugs-icon .c-green {
        background-color: var(--entry-label);
        margin-left: -.5rem;
        backdrop-filter: blur(6px);
        -webkit-backdrop-filter: blur(6px)
    }
}

.b-nobugs-text {
    align-self: center;
    font-weight: 700;
    font-size: .875rem
}

.b-nobugs-text>span {
    display: block
}

.b-nobugs+.b-cont {
    position: relative;
    z-index: 0;
    border-top: 1px solid var(--line)
}

.b-nobugs+.b-cont::before {
    content: "";
    position: absolute;
    left: .25rem;
    right: .25rem;
    top: 0;
    height: 3.75rem;
    margin-top: .25rem;
    background-size: 1rem 1rem;
    opacity: .05;
    z-index: -1;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nIzE0MjYzNicgZD0nTTIsOGEyLDIsMCwxLDAsMiwyQTIsMiwwLDAsMCwyLDhabTgtOGEyLDIsMCwxLDAsMiwyQTIsMiwwLDAsMCwxMCwwWicvPjwvc3ZnPg==)
}

.downline-line-list {
    margin-top: -.5rem
}

.download-line {
    color: #fff !important;
    display: block;
    min-height: 3rem;
    padding: 1rem;
    border-radius: .75rem;
    margin-top: .5rem;
    transition: box-shadow .2s ease
}

.download-line-title {
    display: flex;
    width: 100%;
    min-width: 0;
    flex: 1 1 auto;
    font-weight: 700
}

.download-line-title svg {
    display: block
}

.download-line-size {
    font-size: .75rem;
    white-space: nowrap
}

@media(max-width:575px) {
    .download-line-title>span {
        order: -1;
        line-height: 1.4;
        min-width: 0;
        word-wrap: break-word
    }

    .download-line-title>i {
        margin-left: auto
    }

    .download-line-size {
        opacity: .5
    }
}

@media(min-width:576px) {
    .download-line {
        display: flex;
        border-radius: 1.5rem;
        padding: .5rem
    }

    .download-line-title {
        align-items: center;
        padding: 0 2rem 0 .5rem
    }

    .download-line-title>i {
        margin-right: .5rem;
        opacity: .5
    }

    .download-line-title>span {
        white-space: nowrap;
        overflow: hidden;
        line-height: 1.5rem;
        text-overflow: ellipsis
    }

    .download-line-size {
        margin-left: auto;
        background-color: rgba(0, 0, 0, .2);
        font-size: .875rem;
        height: 2rem;
        line-height: 1.5rem;
        padding: .25rem .75rem;
        border-radius: 1rem
    }
}

.mod-info {
    display: block;
    font-weight: 700;
    font-size: 1.125rem
}

.info-line {
    display: flex;
    align-items: flex-start;
    font-size: .875rem;
    margin-top: 1.5rem
}

.info-line-icon {
    margin-right: .5rem
}

.info-line-icon svg {
    display: block
}

.info-line>span {
    align-self: center;
    line-height: 1.4rem;
    flex: 1 1 auto
}

.b-comments .info-line {
    background-color: rgba(254, 222, 74, .2);
    border-radius: .75rem;
    padding: .75rem;
    margin-top: 0;
    color: var(--nocomms)
}

.downline-line-list+.spoiler {
    margin-top: 1.5rem
}

.view-news-head .wrp-min {
    padding-bottom: 20rem
}

.view-news-head.dark-head {
    background-image: radial-gradient(closest-side, rgba(55, 121, 157, .2) 0, rgba(55, 121, 157, 0) 100%);
    background-size: 35rem 35rem;
    background-position: 50% -30%;
    background-repeat: no-repeat
}

.view-news-head+.block-list {
    margin-top: -18rem;
    position: relative
}

.view-news-head .meta-date {
    background-color: rgba(255, 255, 255, .1)
}

.b-article .img {
    padding-top: 56.5%;
    position: relative
}

.b-article .img .fit-cover {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.b-article .img .fit-cover img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.b-article>.b-cont .read-next {
    margin-top: 1.5rem
}

.read-next {
    border-radius: .5rem;
    padding: 1.25rem 1.5rem;
    border: 1px solid rgba(0, 0, 0, .1);
    position: relative;
    box-shadow: 0 .25rem 1rem 0 rgba(0, 0, 0, .05)
}

.read-next::before {
    content: attr(title);
    display: block;
    margin-bottom: .5rem;
    color: #4ccb70;
    font-size: .75rem;
    font-weight: 700;
    text-transform: uppercase
}

.read-next>a {
    display: block;
    max-width: 36rem;
    line-height: 1.3;
    font-weight: 700;
    color: inherit
}

.read-next>a::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%
}

.b-article>.b-cont .ya-share2 {
    margin-top: 1.5rem
}

@media(min-width:992px) {
    .read-next {
        transition: transform .2s ease, box-shadow .2s ease
    }

    .read-next:hover {
        transform: translateY(-.25rem);
        box-shadow: 0 .5rem 1rem 0 rgba(0, 0, 0, .05)
    }
}

@media(min-width:640px) {
    .view-news-head .wrp-min {
        display: flex;
        align-items: flex-start
    }

    .b-article>.b-cont {
        padding-left: 3rem;
        padding-right: 3rem
    }

    .view-news-head.dark-head {
        background-size: 50rem 50rem;
        background-position: 30% -20%
    }

    .read-next>a {
        font-size: 1.25rem
    }

    .b-article>.b-cont .ya-share2 {
        margin-top: 2rem
    }
}

@media(max-width:639px) {
    .view-news-head .wrp-min {
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }

    .view-news-head .likes {
        margin-top: 1.5rem
    }

    .view-news-head .likes>a {
        background-color: #142636
    }

    .b-article>.b-cont .ya-share2 {
        text-align: center
    }
}