body {
    background-color: #172b3d;
    color: #fff
}

.page-cdn {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    max-width: 63rem;
    padding: 1rem;
    margin: 0 auto;
    overflow: hidden
}

@media(min-width:576px) {
    .page-cdn {
        padding: 2rem
    }
}

.background {
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    overflow: hidden
}

.background>i {
    position: absolute;
    z-index: -1;
    display: block
}

.bg-clouds {
    top: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    display: flex !important;
    justify-content: center
}

.bg-clouds svg {
    max-width: 2550px;
    min-width: 2550px;
    height: auto
}

.bg-circle-green {
    width: 40rem;
    height: 40rem;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.bg-circle-green {
    background-image: radial-gradient(closest-side, rgba(76, 203, 112, 0.2) 0, rgba(76, 203, 112, 0) 100%)
}

@media(min-width:576px) {
    .bg-circle-green {
        width: 75rem;
        height: 75rem
    }
}

.page-cdn-back>a {
    display: inline-flex;
    vertical-align: top;
    align-items: center;
    font-size: .875rem;
    font-weight: 700;
    color: inherit
}

@media(min-width:992px) {
    .page-cdn-back>a {
        transition: opacity .2s ease
    }

    .page-cdn-back>a:hover {
        opacity: .8
    }
}

.page-cdn-back>a>.c-icon {
    margin-right: 1rem
}

.page-cdn-head {
    margin-bottom: 1rem
}

.page-cdn-cont {
    margin: auto;
    padding: 1.5rem 0 7rem;
    text-align: center;
    width: 100%
}

.logotype {
    padding: 1rem;
    display: inline-block;
    vertical-align: top
}

.download-cdn {
    position: relative
}

@media(max-width:767px) {
    .page-cdn-cont {
        padding-bottom: 3rem
    }
}

@media(min-width:768px) {
    .download-cdn::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -2rem;
        z-index: -1;
        pointer-events: none;
        width: 42rem;
        height: 42rem;
        transform: translate(-50%, -50%);
        border: 1px solid rgba(255, 255, 255, .1);
        border-radius: 50%
    }
}

@media(min-width:992px) {
    .download-cdn::after {
        transition: transform 1s ease
    }

    .download-cdn:hover::after {
        transform: translate(-50%, -50%) scale(1.1)
    }
}

.download-cdn-main {
    margin: 0 auto;
    margin-bottom: 2rem;
    max-width: 26rem;
    display: flex;
    align-items: center
}

.cdn-img {
    width: 100%;
    max-width: 10rem;
    min-width: 10rem;
    position: relative;
    z-index: 1
}

@media(min-width:768px) {
    .cdn-img {
        max-width: 13rem;
        min-width: 13rem
    }
}

.cdn-img .img::after,
.cdn-img .img::before {
    content: "";
    position: absolute;
    z-index: -1;
    pointer-events: none
}

.cdn-img .img::after {
    left: 50%;
    top: 50%;
    width: calc(100% + 6rem);
    height: calc(100% + 6rem);
    transform: translate(-50%, -30%);
    background-image: radial-gradient(closest-side, rgba(0, 0, 0, 0.3) 0, rgba(0, 0, 0, 0) 100%)
}

.cdn-img .img::before {
    width: 16rem;
    height: 11.25rem;
    left: 50%;
    top: 0;
    margin-left: -8rem;
    margin-top: 3rem;
    background-size: 1rem 1rem;
    opacity: .4;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAxNiAxNic+PHBhdGggZmlsbD0nIzE0MjYzNicgZD0nTTIsOGEyLDIsMCwxLDAsMiwyQTIsMiwwLDAsMCwyLDhabTgtOGEyLDIsMCwxLDAsMiwyQTIsMiwwLDAsMCwxMCwwWicvPjwvc3ZnPg==)
}

.cdn-meta {
    min-width: 0;
    width: 100%;
    flex: 1 1 auto;
    opacity: .5;
    margin-top: 1rem;
    font-size: .675rem;
    font-weight: 700;
    position: relative;
    z-index: 2
}

.cdn-meta:first-child {
    padding-right: 1rem
}

.cdn-meta:last-child {
    padding-left: 1rem
}

.cdn-meta>svg {
    display: block;
    margin: 0 auto .25rem
}

.cdn-meta>span {
    display: block;
    height: 2.5em;
    line-height: 1.25em;
    overflow: hidden
}

.page-cdn-cont .title,
.page-cdn-cont .md5 {
    position: relative;
    z-index: 2
}

.page-cdn-cont .title {
    font-size: 1.125rem;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 0
}

.page-cdn-cont .md5 {
    font-size: .75rem;
    opacity: .8
}

.page-cdn-cont .title+.md5 {
    margin-top: .5rem
}

@media(min-width:576px) {
    .page-cdn-cont .title {
        font-size: 1.375rem
    }

    .page-cdn-cont .md5 {
        font-size: .875rem
    }
}

.download-btn-group {
    margin-top: .5rem
}

.btn-icon>svg {
    margin-top: -.125rem
}

.telegram-cdn-btn,
.counter {
    margin-top: 2rem
}

.telegram-cdn-btn,
.download-btn,
.download-btn-group {
    width: 100%;
    max-width: 15rem;
    margin-left: auto;
    margin-right: auto
}

.download-btn>a {
    width: 100%;
    display: block;
    position: relative;
    vertical-align: top;
    color: #fff
}

.download-btn>a>input {
    width: 100%
}

.download-btn>a>input:disabled,
.download-btn>a>input:disabled+svg {
    animation: loading 1s infinite linear
}

.download-btn>a>input:disabled {
    opacity: .4
}

@keyframes loading {
    0% {
        opacity: .4
    }

    50% {
        opacity: .7
    }

    100% {
        opacity: .4
    }
}

.counter #load {
    animation: load-rotate 3s infinite linear
}

@keyframes load-rotate {
    100% {
        transform: rotate(360deg)
    }
}

.counter {
    position: relative;
    width: 3rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto
}

.number-wrapper {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -2rem 0 0 -2rem;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: .25rem solid #fede4a;
    border-bottom-color: #4ccb70;
    border-right-color: #4ccb70
}

.number {
    color: #4ccb70;
    font-size: 2rem;
    font-weight: 700
}

.page-cdn-notimer {
    text-align: center;
    justify-self: center;
    max-width: 16.5rem;
    margin: 0 auto
}

.cdn-notimer-path>* {
    display: block;
    margin: 0 auto
}

@media(max-width:767px) {
    .cdn-notimer-path>i {
        display: none
    }
}

@media(min-width:768px) {
    .cdn-notimer-path>i {
        width: .25rem;
        height: .25rem;
        border-radius: 50%;
        margin-bottom: .5rem;
        background-color: #34495c
    }
}

.cdn-notimer-path>svg {
    margin-top: .75rem;
    margin-bottom: .75rem;
    color: #34495c
}

.cdn-notimer-label {
    background-color: #34495c;
    border-radius: 1.125rem;
    font-size: .875rem;
    font-weight: 700;
    box-shadow: 0 .5rem 1.5rem 0 rgba(0, 0, 0, .16);
    padding: .5rem 1rem
}