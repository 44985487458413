/* =========== В© 2020 Centroarts.com =========== */
/* EDIT POST */
@media (min-width: 992px) {
    .post__edit {
        display: block !important;
        position: relative;
    }

    .post__edit a {
        color: inherit;
        background-color: rgba(0, 0, 0, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        color: #fff !important;
        border-radius: 50%;
        position: relative;
        box-shadow: 0 0 .75rem 0 rgba(0, 0, 0, 0.2);
    }

    .icon_dots {
        height: 4px;
        display: block;
    }

    .icon_dots>i {
        display: inline-block;
        vertical-align: top;
        width: 4px;
        height: 4px;
        margin: 0 1px;
        background-color: #fff;
        border-radius: 50%;
    }

    .post__edit a:hover {
        background-color: rgba(0, 0, 0, 0.8);
    }

    #dropmenudiv {
        padding: .5rem;
        min-width: 140px;
        width: auto !important;
        z-index: 9999 !important;
        opacity: 1 !important;
        display: none;
        font-size: .75rem;
        border-radius: 1rem;
        overflow: hidden;
        box-shadow: 0 .5rem 2.5rem -625rem rgba(0, 0, 0, 0.5);
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
    }

    #dropmenudiv a {
        color: #fff !important;
        display: block;
        padding: .375rem .75rem;
        border-radius: .5rem;
        border: 0 none;
        white-space: nowrap;
    }

    #dropmenudiv a:hover {
        background-color: #4CCB70;
    }
}

/* USERPANEL */
.userpanel>button {
    width: 2.5rem;
    height: 2.5rem;
    border: 0 none;
    background: none;
    padding: 0;
    vertical-align: top;
}

.userpanel .avatar.fit-cover {
    width: 100%;
    height: 100%;
}

.userpanel .avatar.fit-cover::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: inherit;
    height: inherit;
    box-shadow: inset 0 0 .25rem 0 rgba(0, 0, 0, 0.05);
    border-radius: inherit;
}

.userpanel .avatar.fit-cover img {
    background-color: #EAEAEA;
}

.login-pane__info {
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--line);
}

.login-pane__info .avatar.fit-cover {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 auto;
    margin-bottom: .5rem;
}

.login-pane__admin {
    opacity: .5;
    text-transform: lowercase;
}

.login-pane__admin:hover {
    opacity: .8;
}

.login-pane__menu {
    margin-top: 1rem;
}

.login-pane__menu li a {
    color: inherit;
    padding: .25rem 0;
    display: block;
}

.login-pane__menu li a:hover {
    color: #4CCB70;
}

.login-pane__menu li:first-child a {
    border-top-width: 0;
}

/* PM */
.pm-box {
    margin-bottom: 2em;
    background-color: var(--spoiler);
    border-radius: 1.125rem;
}

.pm-status {
    position: relative;
    border-radius: 1.125rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.pm-status-info {
    display: block;
    padding: .5rem 1.25rem;
    border-radius: inherit;
    font-size: .75rem;
}

.pm-menu {
    display: flex;
}

.pm-menu>li {
    width: 100%;
    max-width: 33.333333%;
    flex: 0 0 33.333333%;
}

.pm-menu>li a {
    display: block;
    text-align: center;
    font-size: .875rem;
    font-weight: bold;
    padding: 1.25rem;
    color: inherit !important;
    transition: background-color .2s ease;
}

@media (max-width: 575px) {
    .pm-status-info {
        text-align: center;
    }

    .pm-menu>li a span {
        display: none !important;
    }

    .pm-menu>li a svg {
        display: block !important;
        margin: 0 auto;
    }
}

.pm-menu>li a:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.pm-menu>li:first-child a {
    border-bottom-left-radius: 1.125rem;
}

.pm-menu>li:last-child a {
    border-bottom-right-radius: 1.125rem;
}

.pm_progress_bar {
    width: 100%;
    height: .25rem;
    background-color: #FEDE4A;
    overflow: hidden;
}

.pm_progress_bar span {
    background-color: #4CCB70;
    font-size: 0;
    height: 100%;
    border-radius: inherit;
    display: block;
    overflow: hidden
}

/* PM MSG */
.pm_msg_tools,
.pm_msg_info {
    font-size: .75rem;
}

.pm_msg_tools a,
.pm_msg_info a {
    color: inherit;
}

.pm_msg_info {
    margin-top: 1.5rem;
    text-transform: uppercase;
}

.pm_msg_tools {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: .5rem;
    margin-top: .5rem;
}

.pm_msg_tools a,
.pm_msg_info>* {
    margin-right: 1rem;
}

.pm_msg_tools a:hover {
    color: #4CCB70;
}

.pm_msg .title {
    margin-bottom: 1rem;
}

/*---Р”РѕРїРѕР»РЅРёС‚РµР»СЊРЅС‹Рµ РїРѕР»СЏ---*/
table.xfields {
    width: 100%;
}

.xfields textarea,
.xprofile textarea {
    width: 100%;
    height: 186px;
    margin-top: 5px;
}

.xfields input[type="text"] {
    width: 100%;
}

.xfieldsdescr {
    width: 200px;
}

.xfields .bb-pane+textarea {
    margin-top: 0px;
}

.xfieldsnote {
    color: #838383;
    font-size: .75rem;
}

.xfields_table td {
    vertical-align: top;
}

.xfieldsrow {
    margin-top: 1.5rem;
    clear: both;
}

.xfieldscolleft {
    font-size: .75rem;
    text-transform: uppercase;
    margin-bottom: .5rem;
    opacity: .6;
}

.xfieldscolright {
    width: 100%;
}

.file-box {
    width: 95%;
    max-width: 437px;
    border: 1px solid #B3B3B3;
    border-radius: 3px;
    background-color: #F5F5F5;
    padding: 10px;
    margin-top: 10px;
}

.xfieldimagegallery {
    margin: 0;
    padding: 0;
    list-style: none;
    clear: both;
}

.xfieldimagegallery li {
    list-style: none;
    margin: 0;
    padding: 0;
}

.xfieldimagegallery li img {
    float: left;
    margin-right: 5px;
    border: 5px solid #fff;
    width: 100px;
    height: 100px;
    transition: box-shadow 0.5s ease;
}

.xfieldimagegallery li img:hover {
    box-shadow: 0 0 .5rem 0 rgba(0, 0, 0, 0.4);
}

.qq-uploader {
    position: relative;
    width: 100%;
}

.qq-upload-button {
    display: inline-block;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.qq-upload-drop-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    max-width: 437px;
    background: #FF9797;
    text-align: center;
}

.qq-upload-drop-area span {
    display: block;
    position: absolute;
    top: 50%;
    width: 100%;
    margin-top: -8px;
    font-size: 1rem;
}

.qq-upload-drop-area-active {
    background: #FF7171;
}

.uploadedfile {
    display: inline-block;
    width: 115px;
    height: 160px;
    margin: 10px 5px 5px 5px;
    border: 1px solid #B3B3B3;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);
    text-align: center;
    background: #fff;
}

.uploadedfile .uploadimage {
    margin-top: 5px;
    width: 115px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: move;
    cursor: -webkit-grabbing;
}

.sortable-ghost {
    opacity: 0.4;
}

.uploadedfile .info {
    text-align: left;
    white-space: nowrap;
    margin: 0px 5px 0px 5px;
    overflow: hidden;
}

/* --- РџРѕР»СЏ С„РѕСЂРј --- */
table.xfields input {
    width: 100%;
}

table.xfields tr>td:first-child {
    padding-right: 10px;
}

/* Progress Bar */
.progress {
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 10px;
    border-radius: 8px;
    background: #eee;
    box-shadow: 0 1px 0 white, 0 0px 0 1px rgba(0, 0, 0, 0.1) inset, 0 1px 4px rgba(0, 0, 0, 0.2) inset;
}

.progress .progress-bar {
    float: left;
    width: 0%;
    font-size: 12px;
    line-height: 20px;
    color: white;
    text-align: center;
    background-color: #428bca;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    transition: width 0.6s ease;
    border-radius: 8px;
    box-shadow: none;
    height: 8px;
}

.progress-bar span {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0;
}

.progress-blue {
    background-image: linear-gradient(to bottom, #9bcff5 0%, #6db9f0 100%);
    background-repeat: repeat-x;
    border: 1px solid #55aeee;
}

.st0 {
    animation: circle_rotate 12s infinite linear;
    transform-origin: 50% 50%;
    fill: none;
    stroke: #4CCB70;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-dasharray: 358.478, 200.7477;
}

.st1 {
    animation: circle_rotate 18s infinite linear;
    transform-origin: 50% 50%;
    fill: none;
    stroke: #4CCB70;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-dasharray: 428.294, 239.8446;
}

@keyframes circle_rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}