/* =========== В© 2021 Centroarts.com =========== */

body,
html {
    height: 100%;
}

body {
    display: block;
    background-color: var(--block-bg);
}

.page-form {
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.page-form-right {
    flex: 1;
    height: 100%;
    border-radius: 1.125rem;
    box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    background-color: var(--block-bg);
}

.page-form-right-in {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.page-form_foot {
    font-size: .75rem;
    text-align: center;
}

.copyright {
    padding: 0;
    margin-bottom: 0;
}

.page-form-cont {
    display: flex;
    justify-content: center;
    flex-grow: 1;
}

.page-form-cont .wrp-min {
    padding: 0;
}

.wrp-form,
.wrp-form-mid,
.wrp-form-min {
    width: 100%;
}

.wrp-form-min {
    max-width: 36rem;
}

.wrp-form {
    max-width: 90rem;
}

.wrp-form-mid {
    max-width: 60rem;
}

.pag-form_head {
    line-height: 1.5rem;
    display: flex;
    font-size: .875rem;
    font-weight: bold;
}

.pag-form_head a {
    color: inherit;
    padding: .5rem;
    margin: -.5rem;
}

.pag-form_head a:hover {
    color: #4CCB70;
}

a.back_to_main {
    display: flex;
    align-items: center;
}

a.back_to_main svg {
    margin-right: .5rem;
}

@media (min-width: 992px) {
    .page-form {
        flex-direction: row;
    }

    .page-form-left {
        width: 22rem;
        overflow: hidden;
        position: relative;
        background-color: var(--pageform-bg);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .page-form-left .title {
        font-size: 1.5rem;
        color: var(--main-heading);
        line-height: 1.3;
        margin: 1.5rem 0 0 0;
    }

    .page-form-head {
        padding: 3rem;
        padding-bottom: 0;
    }

    .page-form-head .logotype {
        display: block;
        margin: -.125rem 0;
    }

    .page-form-bg {
        flex-grow: 1;
        min-height: 320px;
        pointer-events: none;
        background-image: var(--pageform-img);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 100%;
    }

    .page-form-bg img {
        max-width: 100%;
        min-width: 58rem;
    }

    .page-form-right {
        margin-left: -1.125rem;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .page-form-cont {
        align-items: center;
    }

    .pag-form_head {
        padding: 1.5rem 2rem;
    }

    .page-form_foot {
        text-align: right;
        padding: 1.5rem 5rem;
    }

    .wrp-form,
    .wrp-form-mid,
    .wrp-form-min {
        padding: 2rem;
        margin: auto;
    }
}

@media (min-width: 1260px) {
    .page-form-left .title {
        font-size: 2rem;
    }

    .page-form-left {
        width: 32rem;
    }

    .page-form-bg {
        min-height: 456px;
    }

    .page-form_foot {
        padding: 1.5rem 2rem;
    }
}

@media (max-width: 991px) {
    .page-form-left {
        padding: 2rem;
        text-align: center;
        background-color: #172b3d;
        position: relative;
    }

    .page-form-left a::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .page-form-right {
        margin-top: -1.5rem;
        z-index: 1;
    }

    .page-form-head .logotype {
        display: block;
        height: 2.25rem;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .page-form-head .logotype svg {
        margin-top: -.75rem;
    }

    .page-form-left .title {
        display: none;
    }

    .wrp-form,
    .wrp-form-mid,
    .wrp-form-min {
        padding: 1.5rem;
        margin: 0 auto;
    }

    .page-form_foot {
        max-width: 20rem;
        margin: 0 auto;
        padding: 1.5rem;
        padding-bottom: 5rem;
    }

    .pag-form_head {
        padding: 1.5rem;
    }
}

/* Р РµРіРёСЃС‚СЂР°С†РёСЏ */
.regtext {
    margin-bottom: 1.5rem;
}

.login_check {
    position: relative;
}

.login_check>input {
    padding-right: 120px;
}

.login_check>.btn {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: bold;
}

#result-registration li {
    list-style: none;
    font-size: 85%;
    margin-top: .5em;
}

#registration>.bbcodes:nth-child(2) {
    order: 1;
}

#registration>.bbcodes:nth-child(1) {
    order: 2;
}

/* РћР±СЂР°С‚РЅР°СЏ СЃРІСЏР·СЊ */
.contacts {
    text-align: center;
}

.contacts>img {
    display: block;
    margin: 0 auto;
    margin-bottom: 1rem;
}

/* РЎС‚Р°С‚РёСЃС‚РёРєР° */
.stat_group {
    margin-top: 3rem;
}

.stat_group>.title {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 120%;
    font-weight: bold;
}

.stat_group>ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.stat_group>ul>li {
    font-size: .875rem;
    padding: .5rem 0;
    border-top: 1px solid var(--line);
}

.stats_big {
    padding: 1.5rem;
    margin-top: -.25rem;
    background-color: #4CCB70;
    color: #fff;
}

.stats_big {
    text-align: center;
}

.stats_big .tab-pane {
    display: flex;
}

.stats_big_item {
    font-size: .625rem;
    display: inline-block;
    width: 100%;
    max-width: 33.333333%;
    flex: 33.333333%;
    padding: 0 10px;
}

.stats_big_item .title {
    display: block;
    margin: 0 0 .5rem 0;
    font-size: calc(2rem + 1vw);
    line-height: 1;
}

@media (max-width: 575px) {
    .stats_big {
        margin-left: -1.5rem;
        margin-right: -1.5rem;
        padding-left: .5rem;
        padding-right: .5rem;
    }
}

@media (min-width: 576px) {
    .stats_big {
        border-radius: .75rem;
    }

    .stats_big_item {
        font-size: .75rem;
    }
}

.stat-tabs {
    text-align: center;
    display: flex;
    height: 2.5rem;
    font-size: .875rem;
    font-weight: bold;
    text-transform: uppercase;
}

.stat-tabs a {
    color: inherit;
    height: 3.5rem;
    padding: .5rem 1rem 1.5rem 1rem;
    line-height: 1.5rem;
    transition: background-color .2s ease;
    border-radius: .75rem;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.stat-tabs a.active {
    background-color: #4CCB70;
    color: #FFF !important;
}